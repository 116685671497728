<template>
  <base-material-card id="calendar">
    <v-navigation-drawer
      v-model="otherCalendarsDrawer"
      fixed
      temporary
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-row
        v-for="(email, i) in $store.getters['auth/accountsCalendar']"
        @click="changeService(email)"
        class="mx-0 mt-2"
        align="center"
        v-bind:class="[
          {
            selected_calendar:
              selectedCalendar === email.calendar_id &&
              selectedEmail === email.email,
          },
        ]"
        :key="i"
      >
        <v-col cols="3" class="py-1"
          ><v-avatar
            :style="
              !(
                selectedCalendar === email.calendar_id &&
                selectedEmail === email.email
              )
                ? ''
                : 'border: 4px solid var(--v-primary-base)'
            "
            v-if="email.calendar_id === 1"
            size="35"
          >
            <v-img :src="email.image">
              <template v-slot:placeholder>
                <v-img src="@/assets/gmail.svg"></v-img>
              </template>
            </v-img>
          </v-avatar>
          <v-avatar
            :style="
              !(
                selectedCalendar === email.calendar_id &&
                selectedEmail === email.email
              )
                ? ''
                : 'border: 3px solid var(--v-primary-base)'
            "
            v-else-if="email.calendar_id == 0"
            size="35"
          >
            <img
              style="height: 30px; width: 30px"
              src="@/assets/mini-logo.png"
            />
          </v-avatar>
          <v-avatar
            :style="
              !(
                selectedCalendar === email.calendar_id &&
                selectedEmail === email.email
              )
                ? ''
                : 'border: 4px solid var(--v-primary-base)'
            "
            v-else
            size="25"
          >
            <v-icon size="25" v-text="email.icon" color="primary"></v-icon>
          </v-avatar>
        </v-col>
        <v-col class="py-1 no-wrap"> {{ email.email }} </v-col>
      </v-row>
      <v-divider></v-divider>
      <div class="pl-5" v-for="key in Object.keys(calendars)" :key="key">
        <h3
          style="color: var(--v-primary-base); text-transform: uppercase"
          class="mt-5"
        >
          <u> {{ key }}</u>
          <v-dialog width="500" v-model="dialogCalendar[key]">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="newCalendar = {}">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <v-card id="add_calendar">
              <v-card-title
                style="text-decoration: underline; text-transform: uppercase"
                >{{
                  dialogCalendarAction === "edit"
                    ? $t("edit_calendar")
                    : $t("add_calendar")
                }}
              </v-card-title>
              <div class="close">
                <v-btn icon @click="dialogCalendar[key] = false" small>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text class="py-0">
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <label v-text="$t('calendars.name')" />
                    <v-text-field
                      id="newCalendarName"
                      outlined
                      dense
                      v-model="newCalendar.name"
                    ></v-text-field>
                  </v-col>
                  <!--
                        <v-col cols="12" md="12">
                          <label v-text="$t('calendars.description')" />
                          <v-textarea
                            outlined
                            dense
                            rows="1"
                            v-model="newCalendar.description"
                          ></v-textarea
                        ></v-col>-->
                  <v-col cols="12" md="12" class="py-0">
                    <v-color-picker
                      dot-size="25"
                      hide-canvas
                      show-swatches
                      mode="hex"
                      swatches-max-height="200"
                      v-model="newCalendar.color"
                    ></v-color-picker>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  outlined
                  @click="dialogCalendar[key] = false"
                  style="height: 25px; width: 100px"
                  elevation="0"
                  class="mr-1"
                  >{{ $t("cancel") }}</v-btn
                >
                <v-btn
                  elevation="0"
                  @click="saveCalendar(key)"
                  style="
                    width: 100px !important;
                    color: #363533;
                    height: 25px;
                    padding-top: 10px !important;
                  "
                  class="ml-2"
                >
                  <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
                  {{ $t("save", { name: $t("") }) }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </h3>
        <v-row>
          <v-col cols="12" md="10">
            <v-checkbox
              v-model="checkbox"
              :label="
                checkbox == true ? 'Deseleccionar todo' : 'Seleccionar todo'
              "
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row
          v-for="calendar in calendars[key]"
          :key="calendar.id"
          style="margin-bottom: -20px"
        >
          <v-col class="py-0 check" cols="12" md="10">
            <v-checkbox
              v-model="selectedCalendars"
              multiple
              :label="
                calendar.name === 'reminder'
                  ? $tc(calendar.name)
                  : calendar.name
              "
              :value="calendar.id"
              :color="calendar.color"
              on-icon="mdi-check-circle"
              off-icon="mdi-circle-outline"
              hide-details
            >
              <template v-slot:label="">
                <div class="label-text">
                  {{
                    calendar.name === "reminder"
                      ? $tc(calendar.name)
                      : calendar.name
                  }}
                </div>
                <div class="actions">
                  <v-btn
                    small
                    icon
                    class="ml-1"
                    color="primary"
                    @click.stop="del(calendar.id, key)"
                  >
                    <v-icon small color="primary">$delete</v-icon></v-btn
                  >
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-icon small class="ml-2" v-on="on" color="primary"
                        >mdi-dots-vertical</v-icon
                      >
                    </template>
                    <v-list>
                      <v-list-item
                        class="lessMargin"
                        @click="openEditCalendar(calendar, key)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $t("edit", { name: "" }) }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-dialog
                        v-model="shareDialog"
                        v-if="key != 'Quickink'"
                        width="500"
                      >
                        <template v-slot:activator="{ on }">
                          <v-list-item class="lessMargin" v-on="on">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ $t("share", { name: "" }) }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <v-card id="shareCalendar">
                          <v-card-title
                            style="
                              text-transform: uppercase;
                              text-decoration: underline;
                            "
                          >
                            {{ $t("share", { name: "" }) }}
                          </v-card-title>
                          <v-card-text>
                            <v-row>
                              <v-col class="py-0">
                                <label>{{ $t("email") }}</label>
                                <v-text-field
                                  outlined
                                  dense
                                  v-model="shareEmail"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              outlined
                              @click="
                                shareDialog = false;
                                shareEmail = null;
                              "
                              style="height: 25px; width: 100px"
                              elevation="0"
                              class="mr-1"
                              >{{ $t("cancel") }}</v-btn
                            >
                            <v-btn
                              @click="shareCalendar(calendar, key)"
                              style="
                                width: 100px !important;
                                color: #363533;
                                height: 25px;
                                padding-top: 10px !important;
                              "
                              elevation="0"
                              class="ml-2"
                            >
                              <v-icon style="margin-right: 5px" size="14px"
                                >$save</v-icon
                              >
                              {{ $t("save", { name: $t("") }) }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-list>
                  </v-menu>
                </div>
              </template></v-checkbox
            >
          </v-col>
          <v-col cols="12" md="2">
            <!--
                    <v-btn icon><v-icon>mdi-delete</v-icon></v-btn>
                  -->
          </v-col>
        </v-row>
      </div>
    </v-navigation-drawer>

    <v-row align="center" class="pa-md-3">
      <v-btn
        v-if="!$vuetify.breakpoint.xsOnly"
        class="ma-0"
        @click="showEventForm"
        style="height: 30px"
        elevation="0"
        ><v-icon style="color: black">mdi-plus</v-icon> {{ $tc("new") }}</v-btn
      >
      <v-dialog v-else v-model="selectDialog">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon color="primary">
            <v-icon size="36" color="primary">mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <v-card id="createEventDialog1 ">
          <v-card-text>
            <v-row
              v-if="action === 'save'"
              style="margin-top: -1%; margin-bottom: -3%"
            >
              <v-col
                cols="12"
                md=""
                v-for="key in Object.keys(eventTypes)"
                :key="key"
                class="px-5 py-1"
              >
                <base-border-card
                  :title="$tc(eventTypes[key], 1)"
                  @card_clicked="
                    openingType = eventTypes[key];
                    showEventForm({});
                    selectDialog = false;
                  "
                >
                </base-border-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions> </v-card-actions>
        </v-card>
      </v-dialog>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-tooltip
          bottom
          v-for="(email, i) in $store.getters['auth/accountsCalendar']"
          :key="i"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              outlined
              :style="
                !(
                  selectedCalendar === email.calendar_id &&
                  selectedEmail === email.email
                )
                  ? ''
                  : 'border: 3px solid var(--v-primary-base)'
              "
              dense
              fab
              class="mx-1"
              elevation="0"
              small
              @click="changeService(email)"
            >
              <v-avatar v-if="email.calendar_id === 1" size="35">
                <v-img :src="email.image">
                  <template v-slot:placeholder>
                    <v-img src="@/assets/gmail.svg"></v-img>
                  </template>
                </v-img>
              </v-avatar>
              <v-avatar
                v-else-if="email.calendar_id == 0"
                color="gris4"
                size="35"
              >
                <img
                  style="height: 30px; width: 30px"
                  src="@/assets/mini-logo.png"
                />
              </v-avatar>
              <v-icon
                v-else
                size="25"
                v-text="email.icon"
                color="primary"
              ></v-icon>
            </v-btn>
          </template>
          {{ email.email }}
        </v-tooltip>
      </template>
      <v-col class="py-0" cols="3" v-if="$vuetify.breakpoint.mdAndUp">
        <v-row
          align="center"
          v-bind:style="[
            { 'margin-right': $vuetify.breakpoint.xs ? '45px' : null },
          ]"
        >
          <v-col
            v-if="$store.getters['auth/isStudio']"
            cols="10"
            class="py-0 pl-1"
            v-bind:style="{
              'text-align': $vuetify.breakpoint.xs ? 'end' : '',
            }"
          >
            <v-btn
              icon
              class="my-0 pt-0"
              small
              @click="category_page--"
              :disabled="category_page == 1"
              v-if="artists"
              :style="
                !$vuetify.breakpoint.xsOnly
                  ? 'display: inline-block'
                  : 'display: inline-block; padding-right: 0px; width: 20px;'
              "
            >
              <v-icon large>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              :outlined="!artists"
              @click="
                artists = !artists;
                type = 'day';
              "
              fab
              small
            >
              <v-icon small>$tattooers</v-icon>
            </v-btn>

            <v-btn
              v-if="artists"
              icon
              small
              class=""
              @click="category_page++"
              :disabled="category_page * perPage + 1 > studioTattooers.length"
              :style="
                !$vuetify.breakpoint.xsOnly
                  ? 'display: inline-block'
                  : 'display: inline-block; width: 20px;'
              "
            >
              <v-icon large>mdi-chevron-right</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />

      <v-btn
        icon
        class="my-0 pt-0"
        small
        @click="$refs.calendar.prev()"
        v-if="type != 'agenda'"
        :style="
          !$vuetify.breakpoint.xsOnly
            ? 'display: inline-block'
            : 'display: inline-block; padding-right: 0px; width: 20px;'
        "
      >
        <v-icon large>mdi-chevron-left</v-icon>
      </v-btn>

      <v-menu
        v-if="type === 'month'"
        v-model="datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            style="
              padding-top: 0px !important;
              margin-top: 0px !important;
              width: 130px;
            "
            class="menu mt-n1"
            outlined
            dense
            v-on="on"
            >{{
              start ? $t("months")[new Date(start.date).getMonth() + 1] : "-"
            }}</v-btn
          >
        </template>
        <v-date-picker
          first-day-of-week="1"
          no-title
          v-model="month"
          @input="datePicker = false"
          type="month"
          color="primary"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-else-if="type != 'agenda'"
        v-model="datePicker"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
            style="padding-top: 0px !important; margin-top: 0px !important"
            :style="
              !$vuetify.breakpoint.xsOnly ? 'width: 130px;' : ' width: 95px;'
            "
            class="mt-n1 menu"
            outlined
            dense
            v-on="on"
            @click="openWeek"
          >
            {{ start ? $d(new Date(start.date), "dateShort") : "-" }}
          </v-btn>
        </template>
        <v-date-picker
          first-day-of-week="1"
          v-if="type === 'day'"
          no-title
          v-model="day"
          @input="datePicker = false"
          color="primary"
        ></v-date-picker>

        <v-date-picker
          first-day-of-week="1"
          v-else
          no-title
          v-model="days"
          @input="datePicker = false"
          color="primary"
          range
          ref="week_picker"
        ></v-date-picker>
      </v-menu>
      <v-btn
        v-if="type != 'agenda'"
        icon
        small
        class=""
        @click="$refs.calendar.next()"
        :style="
          !$vuetify.breakpoint.xsOnly
            ? 'display: inline-block'
            : 'display: inline-block; width: 20px;'
        "
      >
        <v-icon large>mdi-chevron-right</v-icon>
      </v-btn>
      <v-col
        cols="6"
        sm="4"
        md="2"
        align="right"
        :style="[
          {
            'max-width': $vuetify.breakpoint.xs
              ? artists
                ? '100px'
                : '150px'
              : null,
          },
        ]"
        class="py-0"
      >
        <v-select
          v-model="type"
          @input="artists = false"
          :items="types"
          dense
          outlined
          :style="
            !$vuetify.breakpoint.xsOnly ? 'width: 130px;' : ' width: 95px;'
          "
          hide-details
          class="selector pr-0"
          append-icon="mdi-chevron-down"
          style="text-transform: uppercase !important"
        ></v-select>
      </v-col>
      <template v-if="$vuetify.breakpoint.xs && type === 'agenda'">
        <v-btn
          icon
          class="my-0 pt-0"
          small
          @click="category_page--"
          :disabled="category_page == 1"
          v-if="artists"
          :style="
            !$vuetify.breakpoint.xsOnly
              ? 'display: inline-block'
              : 'display: inline-block; padding-right: 0px; width: 20px;'
          "
        >
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
          :outlined="!artists"
          @click="
            artists = !artists;
            type = 'day';
          "
          fab
          small
        >
          <v-icon small>$tattooers</v-icon>
        </v-btn>

        <v-btn
          v-if="artists"
          icon
          class="my-0 pt-0"
          small
          @click="category_page++"
          :disabled="category_page * perPage + 1 > studioTattooers.length"
          :style="
            !$vuetify.breakpoint.xsOnly
              ? 'display: inline-block'
              : 'display: inline-block; width: 20px;'
          "
        >
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <template
        v-if="
          $store.getters['auth/isStudio'] &&
            $vuetify.breakpoint.xs &&
            type != 'agenda'
        "
      >
        <v-btn
          icon
          class="my-0 pt-0"
          small
          @click="category_page--"
          :disabled="category_page == 1"
          v-if="artists"
          :style="
            !$vuetify.breakpoint.xsOnly
              ? 'display: inline-block'
              : 'display: inline-block; padding-right: 0px; width: 20px;'
          "
        >
          <v-icon large>mdi-chevron-left</v-icon>
        </v-btn>

        <v-btn
          :outlined="!artists"
          @click="
            artists = !artists;
            type = 'day';
          "
          fab
          small
          ><v-icon small>$tattooers</v-icon>
        </v-btn>

        <v-btn
          v-if="artists"
          icon
          small
          class=""
          @click="category_page++"
          :disabled="category_page * perPage + 1 > studioTattooers.length"
          :style="
            !$vuetify.breakpoint.xsOnly
              ? 'display: inline-block'
              : 'display: inline-block; width: 20px;'
          "
        >
          <v-icon large>mdi-chevron-right</v-icon>
        </v-btn>
      </template>
    </v-row>

    <v-card
      raised
      elevation="6"
      class="pb-3 pl-md-5 pr-md-5 mt-sm-0 pt-6 pt-sm-0 mt-md-8"
    >
      <v-row class="px-md-4">
        <v-btn
          @click="
            $vuetify.breakpoint.smAndDown
              ? (otherCalendarsDrawer = !otherCalendarsDrawer)
              : (otherCalendars = !otherCalendars)
          "
          fab
          x-small
          elevation="1"
          color="primary"
          style="position: absolute; left: 8px; z-index: 9"
          v-bind:style="[
            { top: $vuetify.breakpoint.smAndDown ? '20px' : '20px' },
          ]"
          ><v-icon color="black">{{
            otherCalendars ? "mdi-close" : "mdi-menu"
          }}</v-icon></v-btn
        >

        <v-col
          cols="12"
          md="2"
          style="
            max-height: 600px;
            overflow-x: hidden;
            overflow-y: auto;
            max-width: 200px;
          "
          v-if="otherCalendars && !$vuetify.breakpoint.smAndDown"
        >
          <div v-for="key in Object.keys(calendars)" :key="key">
            <h3
              style="
                margin-top: 50px;
                color: var(--v-primary-base);
                text-transform: uppercase;
              "
            >
              <u> {{ key }}</u>
              <v-dialog width="350" v-model="dialogCalendar[key]">
                <template v-slot:activator="{ on: onDialog }">
                  <v-menu v-if="studioTattooersFiltered.length">
                    <template v-slot:activator="{ on: onMenu }">
                      <v-btn
                        icon
                        v-on="onMenu"
                        @click="
                          dialogCalendarAction = 'save';
                          newCalendar = {};
                        "
                        small
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-on="onDialog"
                        @click="select_tattooer = false"
                      >
                        Añadir calendario
                      </v-list-item>
                      <v-list-item
                        v-on="onDialog"
                        @click="select_tattooer = true"
                      >
                        Añadir calendario associado
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                    v-else
                    icon
                    v-on="onDialog"
                    @click="
                      dialogCalendarAction = 'save';
                      newCalendar = {};
                      select_tattooer = false;
                    "
                    small
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <v-card id="add_calendar">
                  <v-card-title
                    style="
                      text-decoration: underline;
                      text-transform: uppercase;
                    "
                    >{{
                      dialogCalendarAction === "edit"
                        ? $t("edit_calendar")
                        : $t("add_calendar")
                    }}
                  </v-card-title>
                  <div class="close">
                    <v-btn icon @click="dialogCalendar[key] = false" small>
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12" md="12">
                        <label v-text="$t('calendars.name')"/>
                        <v-text-field
                          outlined
                          hide-details
                          dense
                          v-model="newCalendar.name"
                        ></v-text-field></v-col
                      ><!--
                        <v-col cols="12" md="12">
                          <label v-text="$t('calendars.description')" />
                          <v-textarea
                            outlined
                            dense
                            rows="1"
                            v-model="newCalendar.description"
                          ></v-textarea
                        ></v-col>-->
                      <v-col cols="12" v-if="select_tattooer">
                        <label v-text="'Artistas sin calendario asociado'" />

                        <v-select
                          outlined
                          dense
                          :items="studioTattooersFiltered"
                          hide-details
                          v-model="newCalendar.tattooer_id"
                          :item-text="
                            (x) =>
                              `${x.tattooer.user.first_name} ${
                                x.tattooer.user.last_name
                                  ? x.tattooer.user.last_name
                                  : ''
                              }`
                          "
                          item-value="tattooer_id"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-color-picker
                          dot-size="25"
                          hide-canvas
                          show-swatches
                          mode="hex"
                          swatches-max-height="200"
                          v-model="newCalendar.color"
                        ></v-color-picker>
                      </v-col>
                      <v-row justify="end">
                        <v-card-actions>
                          <v-col cols="12" md="12" class="pr-4">
                            <v-btn
                              outlined
                              @click="dialogCalendar[key] = false"
                              style="height: 25px; width: 100px"
                              elevation="0"
                              class="mr-1"
                              >{{ $t("cancel") }}</v-btn
                            >
                            <v-btn
                              v-if="dialogCalendarAction === 'save'"
                              elevation="0"
                              @click="saveCalendar(key)"
                              style="
                                width: 100px !important;
                                color: #363533;
                                height: 25px;
                                padding-top: 10px !important;
                              "
                              class="ml-2"
                            >
                              <v-icon style="margin-right: 5px" size="14px"
                                >$save</v-icon
                              >
                              {{ $t("save", { name: $t("") }) }}
                            </v-btn>
                            <v-btn
                              v-if="dialogCalendarAction === 'edit'"
                              elevation="0"
                              @click="editCalendar(key)"
                              style="
                                width: 100px !important;
                                color: #363533;
                                height: 25px;
                                padding-top: 10px !important;
                              "
                            >
                              <v-icon style="margin-right: 5px" size="14px"
                                >$save</v-icon
                              >
                              {{ $t("save", { name: $t("") }) }}
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                      </v-row>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </h3>
            <v-row>
              <v-col cols="12" md="10">
                <v-checkbox
                  v-model="checkbox"
                  :label="
                    checkbox == true ? 'Deseleccionar todo' : 'Seleccionar todo'
                  "
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row
              v-for="calendar in calendars[key]"
              :key="calendar.id"
              class="calendar-row"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-col class="py-0 check" v-on="on">
                    <v-checkbox
                      class="mt-0"
                      v-model="selectedCalendars"
                      multiple
                      :value="calendar.id"
                      :color="calendar.color"
                      on-icon="mdi-check-circle"
                      off-icon="mdi-circle-outline"
                      hide-details
                      style="display: inline-block"
                    >
                      <template v-slot:label="">
                        <div class="label-text">
                          {{
                            calendar.name === "reminder"
                              ? $tc(calendar.name)
                              : calendar.name
                          }}
                        </div>
                        <div class="actions">
                          <v-btn
                            small
                            icon
                            class="ml-1"
                            color="primary"
                            @click.stop="del(calendar.id, key)"
                          >
                            <v-icon small color="primary">$delete</v-icon>
                          </v-btn>
                          <v-menu>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                small
                                class="ml-2"
                                v-on="on"
                                color="primary"
                                >mdi-dots-vertical</v-icon
                              >
                            </template>
                            <v-list>
                              <v-list-item
                                class="lessMargin"
                                @click="openEditCalendar(calendar, key)"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ $t("edit", { name: "" }) }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-dialog
                                v-model="shareDialog"
                                v-if="key != 'Quickink'"
                                width="500"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-list-item class="lessMargin" v-on="on">
                                    <v-list-item-content>
                                      <v-list-item-title>
                                        {{ $t("share", { name: "" }) }}
                                      </v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </template>
                                <v-card id="shareCalendar">
                                  <v-card-title
                                    style="
                                      text-transform: uppercase;
                                      text-decoration: underline;
                                    "
                                  >
                                    {{ $t("share", { name: "" }) }}
                                  </v-card-title>
                                  <form action data-vv-scope="share-form">
                                    <v-card-text>
                                      <v-row>
                                        <v-col class="py-0">
                                          <label>{{ $t("email") }}</label>
                                          <v-text-field
                                            outlined
                                            dense
                                            data-vv-validate-on="blur"
                                            v-validate="
                                              'required|email|isGmail'
                                            "
                                            :error-messages="
                                              errors.collect(
                                                'share-form.shareEmail'
                                              )
                                            "
                                            data-vv-name="shareEmail"
                                            v-model="shareEmail"
                                          ></v-text-field>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </form>

                                  <v-card-actions>
                                    <v-spacer />
                                    <v-btn
                                      outlined
                                      @click="
                                        shareDialog = false;
                                        shareEmail = null;
                                      "
                                      style="height: 25px; width: 100px"
                                      elevation="0"
                                      class="mr-1"
                                      >{{ $t("cancel") }}</v-btn
                                    >
                                    <v-btn
                                      @click="shareCalendar(calendar, key)"
                                      style="
                                        width: 100px !important;
                                        color: #363533;
                                        height: 25px;
                                        padding-top: 10px !important;
                                      "
                                      elevation="0"
                                      class="ml-2"
                                    >
                                      <v-icon
                                        style="margin-right: 5px"
                                        size="14px"
                                        >$save</v-icon
                                      >
                                      {{ $t("save", { name: $t("") }) }}
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-list>
                          </v-menu>
                        </div>
                      </template>
                    </v-checkbox>
                  </v-col>
                </template>
                <span>
                  {{
                    calendar.name === "reminder"
                      ? $tc(calendar.name)
                      : calendar.name
                  }}</span
                >
              </v-tooltip>
            </v-row>
          </div>
        </v-col>

        <v-col>
          <v-sheet
            style="
              height: calc(100vh - 200px);
              overflow: auto;
              margin-left: 20px;
            "
          >
            <AgendaView
              v-if="type === 'agenda'"
              :selectedCalendars="selectedCalendars"
              :selectedEmail="selectedEmail"
              :selectedCalendar="selectedCalendar"
              @to_day="toDay"
              @edit="openEditEvent"
            />
            <v-calendar
              v-else
              :style="
                $vuetify.breakpoint.lgAndDown
                  ? ''
                  : otherCalendars
                  ? 'width: 100%;'
                  : ''
              "
              :categories="category"
              category-show-all
              category-hide-dynamic
              ref="calendar"
              v-model="value"
              :type="artists ? 'category' : type"
              :events="events"
              @click:event="showEvent"
              @click:day="showEventForm"
              @click:date="showEventForm"
              @click:time="showEventForm"
              @click:more="viewDay"
              @change="loadEvents"
              event-color="none"
              :event-category="eventCategory"
              :weekdays="[1, 2, 3, 4, 5, 6, 0]"
              :weekday-format="fullDayName"
              event-more
              :first-interval="firstHourGlobal"
              :interval-minutes="60"
              :interval-count="interval"
              :class="type == 'day' ? 'day' : ''"
            >
              <template v-slot:category="{ category }">
                <v-row align="center" justify="center" class="px-3">
                  <v-btn
                    icon
                    small
                    @click.stop="order(category, 'prev')"
                    color="primary"
                    v-if="position(category) > 0"
                  >
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    small
                    @click.stop="order(category, 'next')"
                    color="primary"
                    v-if="position(category) !== studioTattooers.length - 1"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-col cols="12" class="pa-0">
                    <v-chip
                      class="no-wrap d-block text-center px-2 ma-1 tattooers"
                      v-bind:style="[
                        {
                          color: $store.getters['app/isLight'](
                            categoryColor(category)
                          )
                            ? 'white'
                            : 'black',
                        },
                      ]"
                      style="
                        border: 2px solid var(--v-primary-base);
                        height: 30px;
                        font-size: 14px !important;
                      "
                      :color="categoryColor(category)"
                    >
                      {{ category }}
                    </v-chip>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:event="{ event }" align="center">
                <div
                  v-if="type == 'day' || type == 'week' || type == '4day'"
                  style="
                    width: 100%;
                    height: 140%;
                    color: black;

                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  "
                  v-bind:style="[
                    { 'background-color': event.color },
                    {
                      color: $store.getters['app/isLight'](event.color)
                        ? 'white'
                        : 'black',
                    },
                  ]"
                  :class="event.color == 'primary' ? 'primary-background' : ''"
                  class="px-md-2 pt-0 px-1 mb-1"
                >
                  {{ event.name }}<br />
                  <span v-if="true"
                    >{{
                      +event.start.substring(11, 13) === 12
                        ? 12
                        : +event.start.substring(11, 13) % 12
                    }}:{{ event.start.substring(14, 16)
                    }}{{ +event.start.substring(11, 13) >= 12 ? "pm" : "am" }}-
                    {{
                      +event.end.substring(11, 13) === 12
                        ? 12
                        : +event.end.substring(11, 13) % 12
                    }}:{{ event.end.substring(14, 16)
                    }}{{ +event.end.substring(11, 13) >= 12 ? "pm" : "am" }}
                  </span>
                  <span v-else>
                    {{
                      $d(new Date(event.start), "calendar")
                        .split(".")[0]
                        .split(" ")[0]
                    }}
                    -
                    {{
                      $d(new Date(event.end), "calendar")
                        .split(".")[0]
                        .split(" ")[0] +
                        ($d(new Date(event.end), "calendar")
                          .split(" ")[1]
                          .split(".")[0] == "a"
                          ? am
                          : pm)
                    }}
                  </span>
                </div>
                <div
                  v-else-if="
                    event.start.length > '10' &&
                      event.start[event.start.length - 2] == '0' &&
                      event.start[event.start.length - 1] == '0'
                  "
                  style="
                    width: 100%;
                    height: 140%;
                    color: black;
                    margin-top: -3px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-top: 2px;
                  "
                  v-bind:style="[
                    { 'background-color': event.color },
                    {
                      color: $store.getters['app/isLight'](event.color)
                        ? 'white'
                        : 'black',
                    },
                  ]"
                  :class="event.color == 'primary' ? 'primary-background' : ''"
                  class="px-md-2 pt-md-0 px-1 mb-1"
                >
                  <span
                    >{{
                      +event.start.substring(11, 13) === 12
                        ? 12
                        : +event.start.substring(11, 13) % 12
                    }}{{
                      +event.start.substring(11, 13) >= 12 ? "pm" : "am"
                    }}</span
                  >

                  {{ event.name }}
                </div>
                <div
                  v-else-if="event.start.length > 10"
                  style="
                    width: 100%;
                    height: 140%;
                    color: black;
                    margin-top: -3px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-top: 2px;
                  "
                  v-bind:style="[
                    { 'background-color': event.color },
                    {
                      color: $store.getters['app/isLight'](event.color)
                        ? 'white'
                        : 'black',
                    },
                  ]"
                  :class="event.color == 'primary' ? 'primary-background' : ''"
                  class="px-md-2 pt-md-0 px-1 mb-1"
                >
                  <span>
                    {{
                      +event.start.substring(11, 13) === 12
                        ? 12
                        : +event.start.substring(11, 13) % 12
                    }}:{{ event.start.substring(14, 16)
                    }}{{
                      +event.start.substring(11, 13) >= 12 ? "pm" : "am"
                    }}</span
                  >

                  {{ event.name }}
                </div>
                <div
                  v-else
                  style="
                    width: 100%;
                    height: 140%;
                    color: black;
                    margin-top: -3px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding-top: 2px;
                  "
                  v-bind:style="[
                    { 'background-color': event.color },
                    {
                      color: $store.getters['app/isLight'](event.color)
                        ? 'white'
                        : 'black',
                    },
                  ]"
                  :class="event.color == 'primary' ? 'primary-background' : ''"
                  class="px-md-2 pt-md-0 px-1 mb-1"
                >
                  {{ event.name }}
                </div>
              </template>
              <template v-slot:day-label="{ day, present }">
                <v-chip
                  style="height: 30px"
                  small
                  color="primary"
                  v-if="present"
                  >{{ day }}</v-chip
                >
                <div v-else>{{ day }}</div>
              </template>
              <template v-slot:interval="{ interval }">
                <div>
                  {{ interval }}
                </div>
              </template>
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement || null"
              :nudge-right="
                selectedElement ? 0 : $vuetify.breakpoint.xs ? 0 : 600
              "
              :nudge-bottom="
                selectedElement ? 0 : $vuetify.breakpoint.xs ? 200 : 400
              "
              offset-x
              id="menu"
              style="max-width: 450px"
            >
              <v-card
                :style="
                  $vuetify.breakpoint.xsOnly
                    ? 'min-width: 150px; max-width:350px; border: 1px solid ' +
                      selectedEvent.color
                    : 'min-width: 350px; max-width:450px; border: 1px solid ' +
                      selectedEvent.color
                "
                flat
                v-if="selectedEvent"
                class="ma-0"
                id="eventView"
              >
                <v-toolbar dark>
                  <v-toolbar-title>
                    <v-icon
                      class="mb-1 mr-2"
                      size="14"
                      :color="selectedEvent.color"
                      >mdi-circle</v-icon
                    >
                    <v-tooltip bottom v-if="$vuetify.breakpoint.xs">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{ selectedEvent.name }}</span>
                      </template>
                      {{ selectedEvent.name }}
                    </v-tooltip>
                    <span v-else>{{ selectedEvent.name }}</span>
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    icon
                    class="mt-1"
                    small
                    v-if="
                      !selectedEvent.url && selectedEvent.mail !== 'Reminder'
                    "
                    @click="transform()"
                  >
                    <v-icon>mdi-swap-vertical</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-if="selectedEvent.url"
                    class="mt-1"
                    small
                    :href="selectedEvent.url"
                  >
                    <v-icon size="15">$edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    v-else-if="
                      selectedEvent.start.length > 10 || selectedEvent.all_day
                    "
                    class="mt-1"
                    small
                    @click="openEditEvent(selectedEvent)"
                  >
                    <v-icon size="15">$edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    class="mt-1"
                    small
                    @click="removeEvent(selectedEvent)"
                  >
                    <v-icon size="15">$delete</v-icon>
                  </v-btn>

                  <div class="close">
                    <v-btn icon @click="selectedOpen = false" x-small>
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-toolbar>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="1" class="pr-0 pr-md-5 py-0">
                      <v-icon class="pr-3" color="primary" size="30"
                        >$clock</v-icon
                      >
                    </v-col>
                    <v-col class="py-0">
                      <b
                        v-html="
                          formatEventDate(
                            selectedEvent.start,
                            selectedEvent.end
                          )
                        "
                      ></b>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedEvent.location && false" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon class="pr-3" color="primary" size="30"
                        >$location</v-icon
                      >
                    </v-col>
                    <v-col class="py-0">
                      <b
                        v-html="
                          selectedEvent.location.length > 5
                            ? selectedEvent.location
                            : '-'
                        "
                      ></b>
                    </v-col>
                  </v-row>
                  <v-row v-if="false" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon class="pr-3" color="primary" size="30"
                        >$customers</v-icon
                      >
                    </v-col>
                    <v-col class="py-0">
                      <span
                        v-html="formatAttendees(selectedEvent.attendees)"
                      ></span>
                    </v-col>
                  </v-row>

                  <v-row v-if="selectedEvent.customer" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon class="pr-3" color="primary" size="30"
                        >$customers</v-icon
                      >
                    </v-col>
                    <v-col class="py-0">
                      <span v-html="selectedEvent.customer"></span>
                    </v-col>
                  </v-row>

                  <v-row v-if="selectedEvent.phone" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon size="30" class="pr-3" color="primary">
                        $phone
                      </v-icon>
                    </v-col>
                    <v-col class="py-0">
                      <span v-html="selectedEvent.phone"></span>
                      <WhatsApp :phone_number="selectedEvent.phone" />
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedEvent.box" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon size="30" class="pr-3" color="primary">
                        $boxes
                      </v-icon>
                    </v-col>
                    <v-col class="py-0">
                      <span v-html="selectedEvent.box.name"></span>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedEvent.url" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon color="primary">mdi-link-variant</v-icon>
                    </v-col>
                    <v-col class="py-0">
                      <span>
                        <a :href="selectedEvent.url">{{
                          $tc("appointment", 1)
                        }}</a>
                      </span>
                    </v-col>
                  </v-row>
                  <v-row v-if="selectedEvent.description" align="center">
                    <v-col cols="1" class="pr-5 py-0">
                      <v-icon class="pr-3" color="primary">mdi-text</v-icon>
                    </v-col>
                    <v-col class="py-0">
                      <span v-html="selectedEvent.description"></span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.xs"
      :max-width="$vuetify.breakpoint.lgAndUp ? '580' : 580"
    >
      <form
        action
        data-vv-scope="event-form"
        @submit.prevent="validateForm('event-form')"
      >
        <v-card
          id="createEventDialog"
          v-bind:class="[{ 'pt-0': action === 'edit' }]"
          class="pt-2"
        >
          <div
            class="back"
            v-if="event.client_id != null && event.appointment_type == null"
            @click="event.client_id = null"
          >
            <v-btn icon small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div
            class="back"
            v-else-if="event.appointment_type != null && event.appointment_subtype == null"
            @click="event.appointment_type = null"
          >
            <v-btn icon small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div
            class="back"
            v-else-if="event.appointment_subtype != null"
            @click="event.appointment_subtype = null"
          >
            <v-btn icon small>
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </div>
          <div class="close">
            <v-btn icon @click="dialog = false" small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-title
            class="pb-md-2"
            style="text-decoration: underline; text-transform: uppercase"
            v-if="$vuetify.breakpoint.xs || action === 'edit'"
          >
            {{ event.url ? $tc("appointment") : $tc(event.type) }}
          </v-card-title>

          <v-card-text class="py-2 mt-6 mt-md-0" style="margin-bottom: 1px">
            <v-row
              class="pt-1"
              align="center"
              :style="
                $vuetify.breakpoint.lgAndDown ? 'width: 108%' : 'width: 103.5%'
              "
            >
              <v-col class="py-0">
                <v-text-field
                  outlined
                  dense
                  v-model="event.title"
                  :label="$t('events.title')"
                  data-vv-validate-on="blur"
                  v-validate="'required|min:2'"
                  data-vv-name="title"
                  :error-messages="errors.collect('event-form.title')"
                  class="label-color semidense"
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col
                cols="5"
                md="3"
                v-if="event.type == eventTypes.APPOINTMENT"
              >
                <v-switch hide-details v-model="event.walk_in" class="mt-0">
                  <template v-slot:label>
                    <span class="text-no-wrap">Walk-In</span>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
            <v-row
              v-if="action === 'save' && !$vuetify.breakpoint.xs"
              class="pt-4"
            >
              <v-btn-toggle
                v-model="event.type"
                dense
                mandatory
                :style="'width:100%'"
                active-class="toggle"
              >
                <v-row class="mb-n2 ml-sm-3">
                  <!--<v-col
                    cols="12"
                    sm="4"
                    :md="key === 'APPOINTMENT' ? '2' : ''"
                    v-bind:style="[
                      {
                        'margin-right': $vuetify.breakpoint.smOnly
                          ? ''
                          : key == 'APPOINTMENT'
                          ? '10px'
                          : '-25px',
                      },
                    ]"
                    class="px-5 py-0"
                  >-->
                  <v-btn
                    v-for="key in Object.keys(eventTypes)"
                    :key="key"
                    outlined
                    :value="eventTypes[key]"
                    color="primary"
                    class="btn_toggle mb-0 mr-1"
                    @click="resetMenu"
                    >{{ $tc(eventTypes[key], 1) }}</v-btn
                  >

                  <v-col
                    class="py-0 px-1 mt-n1"
                    v-if="event.type == eventTypes.APPOINTMENT"
                  >
                    <number-input-with-separators
                      outlined
                      dense
                      :label="$t('designs.price_design')"
                      :value="event"
                      valueKey="price"
                      hide-details
                      class="label-color rectangular"
                      rules="required"
                      ref="money"
                    ></number-input-with-separators>
                  </v-col>
                </v-row>
              </v-btn-toggle> </v-row
            ><br />
            <v-row
              id="customer_select"
              v-if="
                event.client_id == null && event.type == eventTypes.APPOINTMENT
              "
              justify="center"
            >
              <v-col cols="6">
                <base-border-card
                  :title="$t('customers.new')"
                  @card_clicked="event.client_id = 0"
                  :two_lines="true"
                >
                </base-border-card>
              </v-col>
              <v-col cols="6">
                <base-border-card
                  :title="$t('customers.existing')"
                  @card_clicked="event.client_id = -1"
                >
                </base-border-card>
              </v-col>
            </v-row>
            <v-row
              v-else-if="
                event.client_id == -1 && event.type == eventTypes.APPOINTMENT
              "
              justify="center"
            >
              <v-col cols="10">
                <v-autocomplete
                  outlined
                  hide-details
                  dense
                  v-model="event.client_id"
                  :label="$t('select', { name: $tc('customer') })"
                  :items="customers"
                  :search-input.sync="searchCustomer"
                  :loading="isLoadingCustomer"
                  cache-items
                  item-value="id"
                  item-text="user.fullname"
                  @change="selectCustomer"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row
              id="studio_select"
              v-else-if="
                !event.studio_id &&
                  event.type == eventTypes.APPOINTMENT &&
                  $store.getters['auth/isTattooer']
              "
              justify="center"
            >
              <v-col
                v-for="(type, i) in tattooerStudios"
                :key="type.id"
                cols="6"
                md="5"
              >
                <v-card @click="selectStudio(type)">
                  <v-card-title class="text-center d-block text-uppercase">
                    {{ type.studio.studio_name }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              id="type_select"
              v-else-if="
                !event.appointment_type && event.type == eventTypes.APPOINTMENT
              "
              justify="center"
            >
              <v-col
                v-for="(type, i) in appointment_types"
                :key="type.id"
                cols="6"
                md="5"
              >
                <v-card @click="selectType(type)">
                  <v-card-title
                    class="text-no-wrap text-center d-block text-uppercase px-0"
                  >
                    {{
                      type.artist_type_id
                        ? $tc("appointments.type." + type.name)
                        : type.name
                    }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              id="subtype_select"
              v-else-if="
                !event.appointment_subtype &&
                  event.appointment_subtype !== '' &&
                  event.type == eventTypes.APPOINTMENT
              "
              justify="center"
            >
              <v-col
                v-for="(type, i) in sub_types"
                :key="type.id"
                cols="6"
                md="5"
              >
                <v-card
                  @click="
                    event.appointment_subtype = type.description;
                    event.appointment_subtype_id = type.id;
                  "
                >
                  <v-card-title class="d-block text-uppercase text-center">
                    {{ type.description }}
                  </v-card-title>
                </v-card>
              </v-col>
            </v-row>

            <template v-else>
              <v-row
                v-if="event.type == eventTypes.EVENT"
                style="margin-bottom: -7px !important"
              >
                <!--<v-col cols="12" md="" class="py-0">
                <v-select
                  dense
                  outlined
                  v-model="event.account"
                  data-vv-validate-on="blur"
                  v-validate="'required'"
                  data-vv-name="account"
                  :error-messages="errors.collect('event-form.account')"
                  :items="accounts"
                  item-text="mail_name"
                  item-value="mail_id"
                  hide-details
                  :label="$t('email')"
                  :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$email' : ''"
                ></v-select
              ></v-col>-->
                <v-col cols="6" md="6" class="py-0 pb-2">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="fecha"
                        :prepend-icon="
                          $vuetify.breakpoint.mdAndUp ? '$clock' : ''
                        "
                        outlined
                        hide-details
                        dense
                        v-validate="'required'"
                        data-vv-name="startDate"
                        :error-messages="errors.collect('event-form.startDate')"
                        :label="$t('events.date')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="fecha"
                        :style="
                          $vuetify.breakpoint.smAndDown
                            ? 'width: 108%; margin-right: 2.5%'
                            : 'width: 103.5%'
                        "
                      />
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="date"
                      no-title
                      scrollable
                      color="primary"
                      :min="today"
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" md="6" class="py-0 pb-2">
                  <v-select
                    dense
                    outlined
                    v-model="event.calendar"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    data-vv-name="calendar"
                    :error-messages="errors.collect('event-form.calendar')"
                    :label="$t('calendar')"
                    :items="calendarComputed"
                    item-text="name"
                    item-value="id"
                    hide-details
                    class="ml-xs-10"
                    :prepend-icon="
                      $vuetify.breakpoint.mdAndUp ? '$calendar' : ''
                    "
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="event.type == eventTypes.APPOINTMENT">
                <v-col class="py-0 pb-2">
                  <v-text-field
                    outlined
                    dense
                    v-model="event.clientName"
                    :label="$t('events.nameClient')"
                    :hint="$t('events.oneMail')"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    data-vv-name="clientName"
                    :error-messages="errors.collect('event-form.clientName')"
                    class="label-color"
                    :prepend-icon="
                      $vuetify.breakpoint.mdAndUp ? '$customers' : ''
                    "
                    :class="[
                      {
                        text_filed_bg: pre_loaded.clientName,
                      },
                    ]"
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="6" class="py-0 pb-2">
                  <v-text-field
                    outlined
                    dense
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$phone' : ''"
                    v-model="event.clientPhone"
                    :label="$t('events.phoneClient')"
                    data-vv-validate-on="blur"
                    data-vv-name="clientPhone"
                    :error-messages="errors.collect('event-form.clientPhone')"
                    class="label-color"
                    :class="[
                      {
                        text_filed_bg: pre_loaded.clientPhone,
                      },
                    ]"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  $store.getters['auth/isStudio'] &&
                    event.type == eventTypes.APPOINTMENT
                "
              >
                <v-col class="py-0 pb-2">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="fecha"
                        :prepend-icon="
                          $vuetify.breakpoint.mdAndUp ? '$clock' : ''
                        "
                        outlined
                        hide-details
                        dense
                        v-validate="'required'"
                        data-vv-name="startDate"
                        :error-messages="errors.collect('event-form.startDate')"
                        :label="$t('events.date')"
                        readonly
                        v-bind:class="{
                          'error--text': dateError || horarioError,
                        }"
                        v-bind="attrs"
                        v-on="on"
                        :value="fecha"
                      />
                    </template>
                    <v-date-picker
                      v-if="hours.length !== 0"
                      first-day-of-week="1"
                      v-model="date"
                      no-title
                      scrollable
                      :allowed-dates="allowedDates"
                      color="primary"
                      :min="today"
                      @input="
                        menu = false;
                        loadSelectors();
                      "
                    >
                    </v-date-picker>
                    <div
                      v-else
                      class="pa-2 primary--text"
                      style="font-size: 12px"
                    >
                      {{ $t("no_timetable") }}
                      <a
                        @click="
                          $router.push({
                            name: 'UserSettings',
                            params: { type: 'alerts', calendar: true },
                          })
                        "
                        style="text-transform: uppercase;
                            font-weight: 900;
                            text-decoration: underline;
                            }"
                        >{{ $t("go") }}</a
                      >
                    </div>
                  </v-menu>
                </v-col>
                <v-col class="py-0 pb-2">
                  <v-text-field
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="email_val ? 'required|email' : 'email'"
                    data-vv-name="clientMail"
                    :error-messages="errors.collect('event-form.clientMail')"
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$email' : ''"
                    v-model="event.clientMail"
                    :label="$t('events.addClient')"
                    :hint="$t('events.oneMail')"
                    class="label-color"
                    hide-details
                    :class="[
                      {
                        text_filed_bg: pre_loaded.clientMail,
                      },
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row
                v-if="
                  $store.getters['auth/isTattooer'] &&
                    event.type == eventTypes.APPOINTMENT
                "
              >
                <v-col class="py-0">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="fecha"
                        :prepend-icon="
                          $vuetify.breakpoint.mdAndUp ? '$clock' : ''
                        "
                        outlined
                        hide-details
                        dense
                        v-validate="'required'"
                        data-vv-name="startDate"
                        :error-messages="errors.collect('event-form.startDate')"
                        :label="$t('events.date')"
                        readonly
                        v-bind:class="{ 'error--text': dateError }"
                        v-bind="attrs"
                        v-on="on"
                        :value="fecha"
                      />
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="date"
                      no-title
                      scrollable
                      :allowed-dates="allowedDates"
                      color="primary"
                      :min="today"
                      @input="
                        menu = false;
                        loadSelectors();
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="py-0 pb-2">
                  <v-text-field
                    outlined
                    dense
                    data-vv-validate-on="blur"
                    v-validate="email_val ? 'required|email' : 'email'"
                    data-vv-name="clientMail"
                    :error-messages="errors.collect('event-form.clientMail')"
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$email' : ''"
                    v-model="event.clientMail"
                    :label="$t('events.addClient')"
                    :hint="$t('events.oneMail')"
                    class="label-color"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="event.type == eventTypes.APPOINTMENT">
                <v-col cols="6" sm="6" md="6" class="py-0 pb-2">
                  <v-select
                    outlined
                    dense
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$studio' : ''"
                    v-model="event.from"
                    :label="$t('appointments.from')"
                    data-vv-validate-on="blur"
                    data-vv-name="from"
                    :error-messages="errors.collect('event-form.from')"
                    class="label-color"
                    hide-details
                    :items="from"
                  ></v-select>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                  v-if="$store.getters['auth/isStudio']"
                >
                  <v-autocomplete
                    outlined
                    dense
                    class="label-color"
                    v-model="event.tattooer_id"
                    prepend-icon="$tattooers"
                    :items="studioTattooers"
                    hide-details
                    :item-text="
                      (x) =>
                        `${x.tattooer.user.first_name} ${
                          x.tattooer.user.last_name
                            ? x.tattooer.user.last_name
                            : ''
                        }`
                    "
                    item-value="tattooer_id"
                    :label="$tc('artist')"
                    data-vv-validate-on="blur"
                    v-validate="''"
                    clearable
                    data-vv-name="studioSelector"
                    :error-messages="
                      errors.collect('event-form.studioSelector')
                    "
                    @change="changeTattooer()"
                  >
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="checkTattooer(data.item)"
                        color="success"
                        class="mr-2"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="red" class="mr-2">
                        mdi-close
                      </v-icon>
                      {{
                        `${data.item.tattooer.user.first_name} ${
                          data.item.tattooer.user.last_name
                            ? data.item.tattooer.user.last_name
                            : ""
                        }`
                      }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                  v-else-if="$store.getters['auth/isTattooer']"
                >
                  <v-select
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$studio' : ''"
                    outlined
                    dense
                    v-model="event.studio_id"
                    :items="tattooerStudios"
                    disabled
                    :item-text="(x) => x.studio.studio_name"
                    item-value="studio_id"
                    :label="$tc('studio', 1)"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    data-vv-name="studioSelector"
                    :error-messages="
                      errors.collect('event-form.studioSelector')
                    "
                    hide-details
                    @change="changeTimeStudio"
                  >
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="data.item.studio.appointments_count === 0"
                        color="success"
                        class="mr-2"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="red" class="mr-2">
                        mdi-close
                      </v-icon>
                      {{ data.item.studio.studio_name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="!boxObligatori && more_data">
                <v-col class="py-0 pb-2" v-if="boxActive">
                  <v-select
                    single-line
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$boxes' : ''"
                    item-value="id"
                    dense
                    :items="boxes"
                    v-model="event.box_id"
                    outlined
                    hide-details
                    :label="$t('boxes.boxs')"
                    data-vv-name="boxes"
                    :error-messages="errors.collect('event-form.boxes')"
                    data-vv-validate-on="blur"
                    v-validate="''"
                    style="border-color: var(--v-gris1-base); height: 30px"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="
                          !data.item.boxes_tattooer_count ||
                            data.item.boxes_tattooer_count == 0
                        "
                        color="success"
                        class="mr-2"
                      >
                        mdi-check</v-icon
                      >
                      <v-icon v-else color="red" class="mr-2">
                        mdi-close</v-icon
                      >
                      {{ data.item.name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row v-if="boxObligatori">
                <v-col class="py-0 pb-2" cols="6" md="6">
                  <v-select
                    single-line
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$boxes' : ''"
                    item-value="id"
                    dense
                    :items="boxes"
                    v-model="event.box_id"
                    outlined
                    hide-details
                    :label="$t('boxes.boxs')"
                    data-vv-name="boxes"
                    :error-messages="errors.collect('event-form.boxes')"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    style="border-color: var(--v-gris1-base); height: 30px"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ data.item.name }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="
                          !data.item.boxes_tattooer_count ||
                            data.item.boxes_tattooer_count == 0
                        "
                        color="success"
                        class="mr-2"
                      >
                        mdi-check
                      </v-icon>
                      <v-icon v-else color="red" class="mr-2">
                        mdi-close</v-icon
                      >
                      {{ data.item.name }}
                    </template>
                  </v-select>
                </v-col>
                <v-col class="py-0 pb-2" cols="6" md="6">
                  <v-select
                    single-line
                    :prepend-icon="
                      $vuetify.breakpoint.mdAndUp ? '$tattooers' : ''
                    "
                    item-value="name"
                    dense
                    :items="appointment_types"
                    v-model="event.appointment_type"
                    outlined
                    hide-details
                    :label="$t('appointments.type_appointment')"
                    data-vv-name="appointment_type"
                    :error-messages="
                      errors.collect('event-form.appointment_type')
                    "
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    style="border-color: var(--v-gris1-base); height: 30px"
                  >
                    <template slot="selection" slot-scope="data">
                      {{ $tc("appointments.type." + data.item.name) }}
                    </template>
                    <template slot="item" slot-scope="data">
                      {{ $tc("appointments.type." + data.item.name) }}
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="6"
                  sm="6"
                  md="6"
                  class="py-0 pt-2"
                  v-if="more_data"
                >
                  <v-text-field
                    outlined
                    dense
                    :prepend-icon="$vuetify.breakpoint.mdAndUp ? '$phone' : ''"
                    v-model="event.clientPhone"
                    :label="$t('events.phoneClient')"
                    data-vv-validate-on="blur"
                    data-vv-name="clientPhone"
                    :error-messages="errors.collect('event-form.clientPhone')"
                    class="label-color"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="event.type === eventTypes.REMINDER">
                <v-col cols="6" md="6" lg="6" class="py-md-0 pb-0 pt-2">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        class="fecha"
                        :prepend-icon="
                          $vuetify.breakpoint.mdAndUp ? '$clock' : ''
                        "
                        outlined
                        hide-details
                        dense
                        v-validate="'required'"
                        data-vv-name="startDate"
                        :error-messages="errors.collect('event-form.startDate')"
                        :label="$t('events.date')"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="fecha"
                      />
                    </template>
                    <v-date-picker
                      first-day-of-week="1"
                      v-model="date"
                      no-title
                      scrollable
                      color="primary"
                      :min="today"
                      @input="menu = false"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="py-md-0 pb-0 pt-2">
                  <v-select
                    outlined
                    dense
                    :items="repeats"
                    v-model="event.repeats"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>

              <v-row
                style="position: relative"
                align="center"
                :justify="$vuetify.breakpoint.xs ? 'center' : null"
              >
                <v-col cols="12" sm="3" md="4" class="py-0 pt-2">
                  <v-switch
                    v-model="event.isAllDay"
                    hide-details
                    class="py-0 mt-0"
                  >
                    <template v-slot:label>
                      <div class="no-wrap">{{ $t("events.allDay") }}</div>
                    </template>
                  </v-switch>
                </v-col>

                <v-col
                  cols="5"
                  sm="3"
                  md="3"
                  lg="3"
                  class="py-0 pr-md-0 pt-2 pr-lg-3"
                >
                  <v-autocomplete
                    :items="hours"
                    :disabled="event.isAllDay"
                    v-model="event.startTime"
                    :label="$t('events.startTime')"
                    dense
                    outlined
                    hide-details
                    data-vv-validate-on="blur"
                    v-validate="event.isAllDay ? '' : 'required'"
                    data-vv-name="startTime"
                    :error-messages="errors.collect('event-form.startTime')"
                    v-bind:class="{ 'error--text': timeError || horarioError }"
                    @change="loadSelectors"
                  ></v-autocomplete>
                </v-col>
                <p
                  class="pa-1 mb-0"
                  style="display: inline-block; text-align: center"
                >
                  -
                </p>
                <v-col
                  cols="5"
                  sm="3"
                  md="3"
                  lg="3"
                  class="py-0 pt-2 pl-md-0 pl-lg-3"
                >
                  <v-autocomplete
                    :items="hours"
                    :disabled="event.isAllDay"
                    v-model="event.endTime"
                    :label="$t('events.endTime')"
                    hide-details
                    dense
                    v-bind:class="{ 'error--text': timeError || horarioError }"
                    data-vv-validate-on="blur"
                    v-validate="event.isAllDay ? '' : 'required'"
                    data-vv-name="endTime"
                    :error-messages="errors.collect('event-form.endTime')"
                    outlined
                    @change="loadSelectors"
                  ></v-autocomplete>
                </v-col>
                <div
                  v-if="hoursSelected(event.startTime, event.endTime) != 0"
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'position: absolute; right: 5px;'
                      : 'position: absolute; right: 30px;'
                  "
                  class=""
                >
                  {{ hoursSelected(event.startTime, event.endTime) }}h
                </div>
              </v-row>

              <v-row
                class="ml-md-5 mr-md-0 mt-n1 py-2"
                v-if="
                  ((this.$store.getters['auth/isStudio'] &&
                    event.tattooer_id != 0) ||
                    this.$store.getters['auth/isTattooer']) &&
                    more_data &&
                    event.type == eventTypes.APPOINTMENT
                "
                align="center"
                ><v-col cols="12" md="" class="py-0">
                  <v-select
                    outlined
                    dense
                    class="label-color"
                    :label="$tc('expenses.concepto', 1)"
                    :items="tattooerPricing.token_payment"
                    item-text="size"
                    return-object
                    hide-details
                    v-model="event.tokenPayment"
                    @input="changeTokenPayment"
                    data-vv-validate-on="blur"
                    v-validate="'required'"
                    data-vv-name="sizeSelector"
                    :error-messages="errors.collect('event-form.sizeSelector')"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="" class="py-0" v-if="more_data">
                  <v-select
                    outlined
                    dense
                    hide-details
                    class="label-color"
                    :label="$t('tattooers.style')"
                    data-vv-validate-on="blur"
                    :items="styles"
                    item-text="name"
                    item-value="id"
                    v-model="event.tattoo_type_id"
                    v-validate="''"
                    data-vv-name="styleSelector"
                  ></v-select>
                </v-col>
                <v-col
                  cols="12"
                  md=""
                  style="margin-right: -10px"
                  class="py-0"
                  v-if="more_data"
                >
                </v-col>
              </v-row>
              <v-row
                v-if="
                  this.event.tattooer_id != null && this.event.tattooer_id == 0
                "
              >
                <v-col class="py-0">
                  <v-row class="pt-6">
                    <v-icon class="pr-2">mdi-account-edit</v-icon>
                    <v-text-field
                      outlined
                      dense
                      @blur="onBlurSet"
                      v-model="otherTattooerMail"
                      :label="$t('events.registerOtherTattooer')"
                      :hint="$t('events.oneMail')"
                    ></v-text-field>
                  </v-row>
                  <v-row>
                    <v-col class="pa-0">
                      <v-chip
                        style="height: 30px"
                        v-if="event.otherTattooerMail.length != 0"
                        class="ma-1"
                        close
                        :color="
                          checkMailFormat(event.otherTattooerMail[0])
                            ? 'primary'
                            : 'error'
                        "
                        @click:close="deleteOtherTattooer()"
                      >
                        <v-avatar left>
                          <v-icon>mdi-account-circle</v-icon>
                        </v-avatar>
                        {{ event.otherTattooerMail[0] }}
                      </v-chip>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row v-if="event.type !== eventTypes.REMINDER && more_data">
                <v-col class="py-0 pt-1 pb-1">
                  <v-text-field
                    outlined
                    dense
                    :prepend-icon="
                      $vuetify.breakpoint.mdAndUp ? '$location' : ''
                    "
                    v-model="event.location"
                    :label="$t('events.location')"
                    class="label-color"
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="event.type !== eventTypes.REMINDER && more_data">
                <v-col class="py-0 pb-1">
                  <v-text-field
                    outlined
                    dense
                    :prepend-icon="
                      $vuetify.breakpoint.mdAndUp ? '$customers' : ''
                    "
                    @blur="onBlurSplit"
                    v-model="guestsString"
                    hide-details
                    :label="$t('events.addGuests')"
                    :hint="$t('events.separateByCommas')"
                    :style="
                      $vuetify.breakpoint.lgAndDown
                        ? ' margin-bottom: 2.3%'
                        : 'margin-bottom: 2.3%'
                    "
                    class="label-color"
                    v-bind:class="[{ 'error--text': email_error }]"
                  ></v-text-field>
                  <v-row class="mt-n4 pl-2 pl-md-10">
                    <v-chip
                      style="height: 30px"
                      v-for="(guest, index) in event.guests"
                      :key="guest"
                      class="ma-1"
                      close
                      :color="checkMailFormat(guest) ? 'primary' : 'error'"
                      @click:close="deleteGuest(index)"
                    >
                      <v-avatar left>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-avatar>
                      {{ guest }}
                    </v-chip>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="
                  (event.type != eventTypes.REMINDER && more_data) ||
                    event.type === eventTypes.APPOINTMENT
                "
              >
                <v-col class="py-0 pt-2">
                  <v-textarea
                    :prepend-icon="
                      $vuetify.breakpoint.mdAndUp ? 'mdi-text' : ''
                    "
                    v-model="event.description"
                    outlined
                    rows="1"
                    dense
                    auto-grow
                    :label="$t('events.description')"
                    class="label-color"
                    style="border-radius: 10px"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="event.type == eventTypes.EVENT">
                <v-col
                  cols="12"
                  class="py-0 pt-2"
                  v-if="$store.getters['auth/isTattooer']"
                >
                  <v-select
                    :items="yesNo"
                    outlined
                    dense
                    hide-details
                    v-model="event.share_studio"
                    label="Quieres compartir el evento con el estudio?"
                  >
                  </v-select>
                </v-col>
                <v-col
                  :cols="$store.getters['auth/isTattooer'] ? 12 : 8"
                  class="py-0 pt-2"
                >
                  <v-select
                    :items="yesNo"
                    outlined
                    dense
                    hide-details
                    v-model="event.occupied"
                    v-validate="'required'"
                    data-vv-name="occupied"
                    :error-messages="errors.collect('event-form.occupied')"
                    data-vv-validate-on="blur"
                    label="Quieres que ocupe un slot de cita?"
                  >
                  </v-select>
                </v-col>
                <v-col
                  cols="4"
                  class="py-0 pt-2"
                  v-if="$store.getters['auth/isStudio']"
                >
                  <v-select
                    :items="boxes"
                    outlined
                    dense
                    hide-details
                    :disabled="event.occupied !== true"
                    v-model="event.box_id"
                    item-text="name"
                    item-value="id"
                    label="Que box?"
                  >
                    <template slot="item" slot-scope="data">
                      <v-icon
                        v-if="
                          !data.item.boxes_tattooer_count ||
                            data.item.boxes_tattooer_count == 0
                        "
                        color="success"
                        class="mr-2"
                      >
                        mdi-check</v-icon
                      >
                      <v-icon v-else color="red" class="mr-2">
                        mdi-close</v-icon
                      >
                      {{ data.item.name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>

              <v-row v-if="dateError || horarioError" class="py-0">
                <v-col
                  class="
                    login_error
                    white--text
                    pa-1
                    text-center
                    rounded-xl
                    my-0
                  "
                  >{{ $t("time_out_calendar") }}</v-col
                >
              </v-row>
            </template>
          </v-card-text>
          <v-card-actions class="py-0">
            <v-row>
              <v-btn
                v-if="event.type !== eventTypes.REMINDER"
                :disabled="
                  !event.appointment_type &&
                    event.type == eventTypes.APPOINTMENT
                "
                class="my-1 mb-5 mt-md-0 mr-1 mr-md-4 ml-5"
                outlined
                @click="
                  more_data = !more_data;
                  errors.clear();
                "
                elevation="0"
                style="height: 30px; max-width: 120px"
                >{{ more_data ? $t("less_data") : $t("more_data") }}</v-btn
              >
              <v-spacer></v-spacer>

              <v-btn
                class="my-1 mb-6 mt-md-0 mr-1 mr-md-4"
                type="submit"
                style="height: 30px; width: 100px"
                elevation="0"
                :disabled="
                  !event.appointment_type &&
                    event.type == eventTypes.APPOINTMENT
                "
                @click="email_val = false"
              >
                <v-icon style="margin-right: 5px" size="14px">$save</v-icon
                >{{ $tc("save") }}</v-btn
              >
              <v-btn
                v-if="event.type === eventTypes.APPOINTMENT"
                class="my-1 mb-6 mt-md-0 mr-1 mr-md-4"
                type="submit"
                style="height: 30px; width: 100px"
                elevation="0"
                :disabled="
                  !event.appointment_type &&
                    event.type == eventTypes.APPOINTMENT
                "
                @click="email_val = true"
              >
                <v-icon style="margin-right: 5px" size="14px">$send</v-icon
                >{{ $t("send", { name: "" }) }}</v-btn
              >
              <v-spacer v-if="$vuetify.breakpoint.xs"></v-spacer>
            </v-row>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </base-material-card>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as moment from "moment";
export default {
  name: "Calendar",
  data() {
    return {
      pre_loaded: {
        clientName: false,
        clientMail: false,
        clientPhone: false,
      },
      appointment_types: [],
      select_tattooer: false,
      isLoadingCustomer: false,
      searchCustomer: "",
      yesNo: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
      from: [
        {
          text: this.$tc("studio"),
          value: "studio",
        },
        {
          text: this.$tc("artist"),
          value: "tattooer",
        },
      ],
      artists: false,
      category_page: 1,
      styles: [],
      more_data: false,
      selectDialog: false,
      dialogCalendar: { Google: false, Quickink: false, Outlook: false },
      dialogCalendarAction: "save",
      openingType: null,
      type: "month",
      today: null,
      email_val: false,
      email_error: false,
      repeats: [
        { text: this.$t("repeats.none"), value: "none" },
        { text: this.$t("repeats.day"), value: "day" },
        { text: this.$t("repeats.week"), value: "week" },
        { text: this.$t("repeats.month"), value: "month" },
        { text: this.$t("repeats.year"), value: "year" },
      ],
      types: [
        { text: this.$t("agenda"), value: "agenda" },
        { text: this.$t("day"), value: "day" },
        { text: this.$t("4day"), value: "4day" },
        { text: this.$t("week"), value: "week" },
        { text: this.$t("month"), value: "month" },
      ],
      otherCalendarsDrawer: false,
      newCalendar: {},
      mode: "stack",
      modes: ["stack", "column"],
      value: "",
      events: [],
      selectedEvent: null,
      selectedElement: null,
      selectedOpen: false,
      selectedMore: false,
      dialog: false,
      shareEmail: null,
      shareDialog: false,
      otherCalendars: false,
      am: "am",
      pm: "pm",
      error: {},
      eventTypes: {
        APPOINTMENT: "appointment",
        EVENT: "event",
        REMINDER: "reminder",
      },
      event: {
        isAllDay: false,
        timeZone: null,
        guests: [],
        clientMail: null,
        clientName: null,
        clientPhone: null,
        otherTattooerMail: [],
        repeats: "none",
        tokenPayment: {
          price: null,
          size: null,
        },
        type: null,
      },
      guestsString: "",
      clientMail: null,
      otherTattooerMail: null,
      tattooerStudios: [],
      tattooerPricing: [],
      studioTattooers: [],
      datePicker: false,
      menu: false,
      menu2: false,
      start: null,
      end: null,
      currentDate: new Date(),
      timeZone: null,
      action: "save",
      calendars: { Quickink: [] },
      dates: [],
      datesTattooer: [],
      selectedCalendars: [],
      eventsAgenda: [],
      dayCurrentAgenda: null,
      res: {
        id: null,
        date: null,
        start: null,
        end: null,
        tattooer: null,
        associate: false,
      },
      boxes: [],
      filters: {
        search: "",
        dates: "",
        tattooers: "",
      },
      options: {},
      timeStudio: [],
      customers: [],
      sub_types: [],
      actual_customer_search: 0,
    };
  },
  computed: {
    checkbox: {
      get() {
        return this.selectedCalendars.length > 0;
      },
      set(value) {
        if (value) {
          let selected = this.calendars.Quickink.map((x) => x.id);
          this.selectedCalendars = selected;
        } else {
          this.selectedCalendars = [];
        }
      },
    },
    ...mapGetters("timetables", ["hours", "firstHourGlobal", "lastHourGlobal"]),
    studioTattooersFiltered() {
      return this.studioTattooers.filter((st) => {
        if (st.studio_calendar_id == null) return true;
        if (this.calendars.Quickink) {
          let calendar = this.calendars.Quickink.find(
            (c) => c.id == st.studio_calendar_id
          );
          if (!calendar) return true;
        }
        return false;
      });
    },
    customersItems() {
      return this.customers;
    },
    category() {
      let end = this.category_page * this.perPage;
      let start = end - this.perPage;
      return this.studioTattooers
        .slice(start, end)
        .map(
          (t) =>
            `${t.tattooer.user.first_name} ${
              t.tattooer.user.last_name ? t.tattooer.user.last_name : ""
            }`
        );
    },
    perPage() {
      if (this.$vuetify.breakpoint.xsOnly) return 3;
      return 10;
    },
    ...mapState("calendar", ["selectedCalendarState", "selectedEmailState"]),
    selectedCalendar: {
      get() {
        return this.selectedCalendarState;
      },
      set(value) {
        this.$store.commit("calendar/SET_SELECTED_CALENDAR", value);
      },
    },
    selectedEmail: {
      get() {
        return this.selectedEmailState;
      },
      set(value) {
        this.$store.commit("calendar/SET_SELECTED_EMAIL", value);
      },
    },
    boxObligatori() {
      if (this.event.type == this.eventTypes.APPOINTMENT && this.boxActive) {
        if (this.$store.getters["auth/isStudio"])
          return (
            this.$store.getters["auth/getSetting"]("boxes_obligatoris") === 1
          );
        else {
          let studio = this.tattooerStudios.find(
            (x) => x.studio_id === this.event.studio_id
          );
          if (studio) {
            //consele.log("studio", studio.studio);
            let set = studio.studio.user.setting.find(
              (s) => s.key === "boxes_obligatoris"
            );
            if (set) return set.value === "1";
          }
        }
      }
      return false;
    },
    boxActive() {
      if (this.event.type == this.eventTypes.APPOINTMENT) {
        if (this.$store.getters["auth/isStudio"])
          return this.$store.getters["auth/getSetting"]("box_active") === 1;
        else {
          let studio = this.tattooerStudios.find(
            (x) => x.studio_id === this.event.studio_id
          );
          if (studio) {
            //consele.log("studio", studio.studio);
            let set = studio.studio.user.setting.find(
              (s) => s.key === "box_active"
            );
            if (set) return set.value === "1";
          }
        }
      }
      return false;
    },

    dateError() {
      if (
        this.date &&
        !this.allowedDates(this.date) &&
        this.event.type == this.eventTypes.APPOINTMENT
      )
        return true;
      return false;
    },
    timeFrom() {
      if (this.timeStudio.length > 0) {
        let min = "23:00";
        let minFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[0];
            if (h <= min) {
              min = h;
            }
          });
        });
        return min;
        /*
        for (let i = 0; i < this.timeStudio.length; i++) {
          for (let k = 0; k < this.timeStudio[i].length; k++) {
            for (let q = 0; q < this.timeStudio[i][k].length; q++) {
              if (this.timeStudio[i][k][q] <= min) {
                min = this.timeStudio[i][k][q];
                minFinal = min;
              }
            }
          }
        }
        let final = minFinal.split(":");
        let fin = final[0];
        if (fin.indexOf("0") != -1) {
          fin = fin.substr(1, 1);
        }
        return fin;*/
      }
      return "00:00";
    },
    interval() {
      let inici = parseInt(this.firstHourGlobal.split(":"));
      let final = parseInt(this.lastHourGlobal.split(":"));
      return final - inici;
    },
    timeTo() {
      if (this.timeStudio.length > 0) {
        let max = "00:00";
        let maxFinal = null;

        this.timeStudio.forEach((dia) => {
          dia.forEach((hora_overtura) => {
            let h = hora_overtura[1];
            if (h >= max) {
              max = h;
            }
          });
        });
        return max;

        /*
        for (let i = 0; i < this.timeStudio.length; i++) {
          for (let k = 0; k < this.timeStudio[i].length; k++) {
            for (let q = 0; q < this.timeStudio[i][k].length; q++) {
              if (this.timeStudio[i][k][q] >= max) {
                max = this.timeStudio[i][k][q];
                maxFinal = max;
              }
            }
          }
        }
        let final = maxFinal.split(":");

        return final[0];*/
      }
      return "24:00";
    },
    timeError() {
      if (
        this.event.startTime !== undefined &&
        this.event.endTime !== undefined
      ) {
        let s = this.event.startTime.split(":");
        let e = this.event.endTime.split(":");
        if (s[0] < e[0]) {
          return false;
        } else if (s[0] > e[0]) {
          return true;
        }

        if (s[1] <= e[1]) {
          return false;
        }
        return true;
      }
      return false;
    },
    horarioError() {
      if (
        this.date &&
        this.event.startTime &&
        this.event.endTime &&
        this.event.type == this.eventTypes.APPOINTMENT
      ) {
        let dh = this.getDayTimetable()(
          this.date,
          this.$store.getters["auth/isStudio"]
            ? this.$store.state.auth.user.studio.id
            : this.event.studio_id,
          this.$store.getters["auth/isStudio"]
            ? this.event.tattooer_id
            : this.$store.state.auth.user.tattooer.id
        );
        console.log("dh");
        console.log(dh);
        let error = true;
        dh.forEach((h) => {
          if (h[0] <= this.event.startTime && h[1] >= this.event.endTime)
            error = false;
        });
        return error;
      }
      return false;
    },
    ...mapState("auth", ["user"]),
    month: {
      get() {
        if (!this.start) return "";
        return this.start.date.substring(0, 7);
      },
      set(value) {
        let currentStartDate = new Date(this.start.date);
        let newCurrentStartDate = new Date(value + "-01");
        let diffMonths =
          (newCurrentStartDate.getFullYear() - currentStartDate.getFullYear()) *
          12;
        diffMonths -= currentStartDate.getMonth();
        diffMonths += newCurrentStartDate.getMonth();
        this.$refs.calendar.move(diffMonths);
      },
    },
    day: {
      get() {
        if (!this.start) return "";
        return this.start.date;
      },
      set(value) {
        let currentStartDate = moment(this.start.date);
        let newCurrentStartDate = moment(value);
        let diff = newCurrentStartDate.diff(currentStartDate, "days");
        this.$refs.calendar.move(diff);
      },
    },

    days: {
      get() {
        if (!this.start) return [];
        //console.log([this.start.date, this.end.date]);
        return [this.start.date, this.end.date];
      },
      set(value) {
        let currentStartDate = moment(this.start.date);
        let newCurrentStartDate = moment(value[0]);
        ////console.log(value[0]);
        if (this.type === "week") {
        }
        let diff = newCurrentStartDate.diff(currentStartDate, "days");
        let back = false;
        if (diff < 0) {
          back = true;
        }
        //consele.log(diff);
        if (this.type === "week") {
          diff /= 7;
        } else {
          diff /= 4;
        }
        if (back) {
          diff -= 1;
        }
        ////console.log(diff);
        this.$refs.calendar.move(diff);
      },
    },
    date: {
      get() {
        return this.event.startDate;
      },
      set(value) {
        this.event.startDate = value;
        this.event.endDate = value;
        if (this.$store.getters["auth/isStudio"]) this.fetchStudioTattooers();
        if (this.$store.getters["auth/isTattooer"]) this.fetchTattooerStudios();
      },
    },
    accounts() {
      let accounts = this.$store.getters["auth/accounts"].filter(
        (x) => x.mail_id !== 3
      );
      accounts.push({ mail_name: "Quickink", mail_id: 0 });
      return accounts;
    },

    fecha() {
      if (this.date) {
        return this.$d(new Date(this.date), "dateLong");
      }
      return "";
    },
    calendarComputed() {
      switch (this.event.account) {
        case 0:
        case 3:
          return this.calendars["Quickink"].filter(
            (x) => x.id != "recordatorios"
          );
        case 1:
          return this.calendars["Google"];
        case 2:
          return this.calendars["Microsoft"];
      }
      return [];
    },
  },
  mounted() {
    if (this.$store.getters["auth/isStudio"]) this.fetchTimeTables();
    this.fetchService();
    this.date2 = new Date().toISOString().substr(0, 10);
    this.today = moment().format("YYYY-MM-DD");
    this.fetchBoxes();
    this.fetchTimeStudio();
    ////console.log("today", this.today);
    this.fetchAllVacations();
    let accounts = this.$store.getters["auth/accountsCalendar"];
    //console.log(accounts);
    let account = accounts.find((a) => {
      //console.log(this.$store.getters["auth/getSetting"]("calendar_id"));
      return (
        a.calendar_id === this.$store.getters["auth/getSetting"]("calendar_id")
      );
    });
    if (!account) {
      account = accounts[0];
    }
    this.selectedCalendar = account.calendar_id;
    this.selectedEmail = account.email;

    if (this.$store.getters["auth/isStudio"]) this.fetchStudioTattooers();

    ////console.log("mounted");
    this.fetchCalendars();
    this.timeZone = -new Date().getTimezoneOffset();
    this.event.timeZone = this.timeZone;
    this.month = moment().format("YYYY-MM");
    if (this.$vuetify.breakpoint.xsOnly) {
      this.type = "agenda";
    }
    if (this.$route.params.open) {
      this.showEventForm({});
    }
    if (this.$route.params.new) {
      this.openingType = this.eventTypes.REMINDER;
      this.showEventForm({});
    }
    if (this.$route.params.event)
      this.showEvent({ event: this.$route.params.event });
  },
  watch: {
    selectedCalendars: {
      handler() {
        this.loadEventsAgenda();
        this.loadEvents({ start: this.start, end: this.end });
      },
      deep: true,
    },
    searchCustomer(val) {
      //console.log(val);
      this.fetchCustomers();
    },
  },

  methods: {
    toDay(day) {
      this.type = "day";
      this.value = day;
    },
    ...mapGetters("timetables", ["getDayTimetable"]),
    ...mapActions("timetables", ["getTimetables", "getTimetableResume"]),
    fetchTimeTables() {
      this.getTimetableResume({
        studio_id: this.$store.getters["auth/isStudio"]
          ? this.$store.state.auth.user.studio.id
          : this.event.studio_id,
      });
      this.getTimetables({
        filters: {
          studio_id: this.$store.getters["auth/isStudio"]
            ? this.$store.state.auth.user.studio.id
            : this.event.studio_id,
        },
      });
    },

    ...mapActions("services", ["getServices"]),
    fetchService() {
      this.getServices({}).then((response) => {
        this.appointment_types = response.data;
      });
    },
    fetchServiceTattooer() {
      this.getServices({ filters: { studio_id: this.event.studio_id } }).then(
        (response) => {
          this.appointment_types = response.data;
          if (this.appointment_types.length == 0) this.fetchService();
        }
      );
    },
    transform() {
      this.$confirm(
        "Está conviertiendo un evento a una cita. ¿Estás seguro?"
      ).then(() => {
        let formData = {
          id: this.selectedEvent.id,
          email: this.selectedEmail,
          calendar: this.selectedEvent.calendar_id,
        };
        this.importEvent(formData).then(() => {
          this.$alert(this.$t("save_ok"));
          this.loadEvents({ start: this.start, end: this.end });
          this.selectedEvent = null;
          this.selectedOpen = false;
        });
      });
    },
    position(category) {
      return this.studioTattooers.findIndex(
        (t) =>
          `${t.tattooer.user.first_name} ${
            t.tattooer.user.last_name ? t.tattooer.user.last_name : ""
          }` == category
      );
    },
    checkTattooer(t) {
      console.log("timetable", t.tattooer_timetable, t);
      let horario = true;
      if (t.tattooer_timetable && this.event.startDate)
        if (
          t.tattooer_timetable[this.dayOfWeek(this.event.startDate)].length == 0
        )
          horario = false;

      return (
        horario &&
        t.tattooer.appointments_count === 0 &&
        t.tattooer.vacation_tattooer_count === 0
      );
    },
    order(category, action) {
      let cs = this.studioTattooers.find(
        (t) =>
          `${t.tattooer.user.first_name} ${
            t.tattooer.user.last_name ? t.tattooer.user.last_name : ""
          }` == category
      );
      this.orderTattooer({
        action,
        tattooer_id: cs.tattooer_id,
        studio_id: cs.studio_id,
        actual_position: cs.order_by,
      }).then((response) => {
        if (response.success) this.fetchStudioTattooers();
      });
    },
    selectCustomer() {
      console.log(this.customers.map((c) => c.id));
      console.log(this.event.client_id);
      let c = this.customers.find((c) => c.id === this.event.client_id);
      console.log(c);
      this.event.clientName = c.user.first_name;
      this.event.clientMail = c.user.email;
      this.event.clientPhone = c.user.phone;
      this.pre_loaded.clientName = c.user.first_name !== null;
      this.pre_loaded.clientMail = c.user.email !== null;
      this.pre_loaded.clientPhone = c.user.phone !== null;
    },
    ...mapActions("tattooers", ["getTypeArtist"]),
    ...mapActions("subservices", ["getSubServices"]),
    selectType(type) {
      console.log(type);
      this.getSubServices({
        filters: {},
        pagination: { itemPerPage: -1 },
        service_id: type.id,
      }).then((response) => {
        this.sub_types = response.data;
        if (this.sub_types.length == 0) this.event.appointment_subtype = "";
        this.event.appointment_type = type.name;
      });
    },
    eventCategory(event) {
      console.log("event", event);
      let t = this.studioTattooers.find((t) => {
        switch (this.selectedCalendar) {
          case 0:
            return t.studio_calendar_id == event.calendar_id;
          case 1:
            return t.studio_calendar_google == event.calendar_id;
        }
      });
      console.log("t", t);
      if (t)
        return `${t.tattooer.user.first_name} ${
          t.tattooer.user.last_name ? t.tattooer.user.last_name : ""
        }`;
    },
    categoryColor(c) {
      let k = Object.keys(this.calendars);
      let cs = this.calendars[k].find((cl) => {
        let t = this.studioTattooers.find(
          (t) =>
            c ==
            `${t.tattooer.user.first_name} ${
              t.tattooer.user.last_name ? t.tattooer.user.last_name : ""
            }`
        );
        switch (this.selectedCalendar) {
          case 0:
            return t.studio_calendar_id == cl.id;
          case 1:
            return t.studio_calendar_google == cl.id;
        }
      });
      if (cs) return cs.color;
      return "red";
    },
    changeTattooer() {
      this.fetchTattooerPricing(this.event.tattooer_id);
      if (this.appointment_types.length === 1) {
        this.event.appointment_type = this.appointment_types[0].name;
      } else if (this.event.appointment_type) {
        let f = this.appointment_types.find(
          (x) => x.name === this.event.appointment_type
        );
        if (!f) {
          this.event.appointment_type = null;
        }
      }
    },
    hoursSelected(start, end) {
      let mins = ["00", "15", "30", "45"];
      let first = parseInt(start);
      let last = parseInt(end) + 1;
      let count = 0;
      if (start && end) {
        let s = new Date();
        s.setHours(start.split(":")[0], start.split(":")[1]);
        let e = new Date();
        e.setHours(end.split(":")[0], end.split(":")[1]);

        for (let x = first; x < last; x++) {
          mins.forEach((y) => {
            let now = new Date();
            now.setHours(x, y);
            if (now.getTime() < e.getTime() && now.getTime() >= s.getTime())
              count++;
          });
        }
      }
      return count / 4;
    },
    openWeek() {
      if (this.type === "week") {
        this.$nextTick(() => {
          this.$refs.week_picker.tableDate = `${this.start.year}-${this.start.month}`;
        });
      }
    },
    ...mapActions("close_days", ["getAll"]),
    fetchAllVacations() {
      this.getAll().then((data) => {
        if (data) {
          this.dates = data;
        }
      });
    },
    ...mapActions("customers", ["getCustomers"]),
    fetchCustomers() {
      console.log("load", this.isLoadingCustomer);
      //if (this.isLoadingCustomer) return;
      this.isLoadingCustomer = true;
      this.actual_customer_search++;
      let search = this.actual_customer_search;
      let filters = {
        itemsPerPage: 20,
        search: this.searchCustomer,
      };
      if (this.$store.getters["auth/isStudio"])
        filters.studio_id = this.$store.state.auth.user.studio.id;
      if (this.$store.getters["auth/isTattooer"])
        filters.tattooer_id = this.$store.state.auth.user.tattooer.id;
      this.getCustomers({
        filters,
        pagination: {},
        loading: false,
      }).then((response) => {
        //console.log(search, this.actual_customer_search);
        if (search == this.actual_customer_search) {
          this.customers = response.data;
          this.isLoadingCustomer = false;
        }
      });
    },
    ...mapActions("close_days_tattooer", ["getAllTattooer"]),
    fetchAllVacationsTattooer(tattooer, studio) {
      this.getAllTattooer({ tattooer_id: tattooer, studio_id: studio }).then(
        (data) => {
          if (data) {
            this.datesTattooer = data;
            //consele.log(this.datesTattooer);
          }
        }
      );
    },
    resetMenu() {
      this.menu = false;
      this.menu2 = false;
    },
    changeTimeStudio() {
      let a = this.tattooerStudios;
      a = a.find((x) => x.studio_id === this.event.studio_id);
      this.dates = a.studio.vacations;
      this.fetchAllVacationsTattooer("none", a.studio.id);
      this.timeStudio = a.studio.timetable;
      this.fetchBoxes();
    },
    allowedDates(date) {
      let a = this.dates.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (a) {
        //consele.log(a);
        return false;
      }
      let t = this.datesTattooer.find((x) => {
        return x.start_date <= date && x.end_date >= date;
      });

      if (t) {
        //consele.log(t);
        return false;
      }

      let h = this.getDayTimetable()(
        date,
        this.$store.getters["auth/isStudio"]
          ? this.$store.state.auth.user.studio.id
          : this.event.studio_id,
        this.$store.getters["auth/isStudio"]
          ? this.event.tattooer_id
          : this.$store.state.auth.user.tattooer.id
      );
      if (h) {
        return h.length > 0;
      }
      return false;
    },
    dayOfWeek(date) {
      let d = new Date(date);

      d = d.getDay() - 1;
      if (d < 0) d = 6;
      return d;
    },
    ...mapActions("calendar", [
      "getCalendars",
      "addCalendar",
      "updateCalendar",
      "deleteCalendar",
      "shareCalendars",
      "getEvents",
      "getEventsAgenda",
      "saveEvent",
      "editEvent",
      "checkEvent",
      "deleteEvent",
      "importEvent",
    ]),
    ...mapActions("boxes", ["getBoxes"]),
    ...mapActions("tattooers", [
      "reservationBox",
      "editTattooerBox",
      "orderTattooer",
    ]),
    fetchBoxes() {
      this.loading = true;
      console.log(this.event.studio_id);
      this.getBoxes({
        pagination: {},
        filters: {
          ...this.filters,
          studio_id: this.event.studio_id,
          date: this.event.startDate,
          start_time: this.event.startTime,
          end_time: this.event.endTime,
        },
      }).then((boxes) => {
        ////console.log(boxes);

        this.boxes = boxes.data;
        ////console.log(this.items);
        this.loading = false;
      });
    },
    reservation() {
      this.reservationBox({
        tattooer_id: this.res.tattooer,
        formData: this.res,
      }).then((data) => {
        ////console.log(data);
        if (data.sentencia == false) {
          this.fetchBoxes();
          this.fetchTable();
          this.dialog = false;
        } else {
          this.$alert(
            "No hay disponibilidad para esta fecha en este box",
            "",
            ""
          );
        }
      });
    },
    changeTokenPayment() {
      this.$nextTick(() => {
        if (this.$refs.money) this.$refs.money.change();
      });
    },
    changeService(service) {
      this.selectedCalendar = service.calendar_id;
      this.selectedEmail = service.email;
      this.fetchCalendars();
    },
    loadEvents({ start, end }) {
      let timezone = -new Date().getTimezoneOffset(),
        h = Math.floor(timezone / 60),
        m = Math.abs(timezone) % 60;
      this.start = start;
      this.end = end;
      timezone =
        (h < -10 ? h : h < 0 ? "-0" + -h : h < 10 ? "+0" + h : "+" + h) +
        ":" +
        (m < 10 ? "0" + m : m);

      start = start.date + "T00:00:00" + timezone;
      end = end.date + "T23:59:59" + timezone;

      this.getEvents({
        start,
        end,
        timezone,
        account: this.selectedCalendar,
        email: this.selectedEmail,
        calendars: this.selectedCalendars,
      }).then((events) => {
        this.events = events;
      });
    },

    loadEventsAgenda() {
      this.getEventsAgenda({
        calendars: this.selectedCalendars,
        account: this.selectedCalendar,
        email: this.selectedEmail,
      }).then((events) => {
        this.eventsAgenda = events;
        ////console.log(this.eventsAgenda);
      });
    },
    ...mapActions("appointments", ["getEventCalendarAppointment"]),
    showEvent({ nativeEvent, event }) {
      console.log("TEST");
      if (nativeEvent) nativeEvent.stopPropagation();
      this.getEventCalendarAppointment(event.id).then((response) => {
        this.selectedEvent = {};
        const open = () => {
          if (response) {
            event.customer = response.customer.user.fullname;
            event.phone = response.customer.user.phone;
            event.box = response.available_dates[0].boxes_tattooer
              ? response.available_dates[0].boxes_tattooer.boxes
              : null;
          }
          this.$set(this, "selectedEvent", event);

          if (nativeEvent) this.selectedElement = nativeEvent.target;
          setTimeout(() => (this.selectedOpen = true), 10);
        };

        if (this.selectedOpen) {
          this.selectedOpen = false;
          setTimeout(open, 100);
        } else {
          open();
        }
      });
    },
    what(item) {
      ////console.log(item);
    },
    viewDay({ date }) {
      this.type = "day";
      ////console.log(date);
      this.value = date;
    },
    dayView(date) {
      this.type = "day";

      this.value = date;
    },
    agendaDay(date) {
      ////console.log(date);
      let arr = Object.keys(date);

      ////console.log(arr);
      for (let i = 0; i < arr.length; i++) {
        ////console.log(new Date(this.today).toLocaleDateString());
        if (
          new Date(arr[i]).toLocaleDateString() ===
          new Date(this.today).toLocaleDateString()
        ) {
          ////console.log(arr[i]);
          this.dayCurrentAgenda = new Date(arr[i]).toLocaleDateString();
        }
      }
      ////console.log(this.dayCurrentAgenda);
    },
    fetchCalendars() {
      ////console.log("fetch");
      this.getCalendars({
        account: this.selectedCalendar,
        email: this.selectedEmail,
      }).then((data) => {
        if (!data) {
          this.selectedCalendar = this.$store.getters[
            "auth/accountsCalendar"
          ][0].calendar_id;
          this.selectedEmail = this.$store.getters[
            "auth/accountsCalendar"
          ][0].email;
          this.$confirm(this.$t("google_permision_error")).then(() => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "connections" },
            });
          });
        } else {
          this.selectedCalendars = [];
          Object.keys(data).forEach((key) => {
            this.selectedCalendars.push(...data[key].map((x) => x.id));
          });
          ////console.log(data);
          this.calendars = data;
          this.loadEvents({ start: this.start, end: this.end });
          this.loadEventsAgenda();
        }
      });
    },
    showEventForm({ date, time }) {
      let a = new Date(date);
      let s = new Date(this.start);
      let e = new Date(this.end);
      if (a.getTime() < s.getTime() || a.getTime() > e.getTime()) return;
      /*
      if (this.hours.length === 0 || this.$store.getters['auth/getLaborExange'] === null) {
        this.$confirm(this.$t("appointments.info_timetable"), "", "").then(
          () => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "alerts", calendar: true },
            });
          }
        );
        return;
      }
      */
      this.errors.clear();

      let today = new Date();
      let dateDate = new Date(date);
      ////console.log(today, dateDate);
      if (today.getTime() > dateDate.getTime()) date = this.today;
      this.event = {
        startDate: date,
        endDate: date,
        isAllDay: false,
        walk_in: false,
        timeZone: this.timeZone,
        guests: [],
        account: this.selectedCalendar,
        email: this.selectedEmail,
        clientMail: null,
        clientName: null,
        clientPhone: null,
        otherTattooerMail: [],
        repeats: "none",
        price: null,
        tokenPayment: {
          price: null,
          size: null,
        },
        type: this.eventTypes.APPOINTMENT,
        from: this.$store.getters["auth/isStudio"] ? "studio" : "tattooer",
        client_id: null,
        appointment_type: null,
        appointment_subtype: null,
        share_studio: false,
        occupied: null,
        studio_id: null,
      };
      if (this.openingType) {
        this.event.type = this.openingType;
        this.openingType = null;
      }
      ////console.log(time);
      if (time) {
        let startH = +time.split(":")[0];
        if (startH < 10) startH = "0" + startH;
        this.$set(this.event, "startTime", startH + ":00");
        let endH = +startH + 1;
        if (endH < 10) endH = "0" + endH;

        this.$set(this.event, "endTime", endH + ":00");
      }
      if (this.calendarComputed.length === 1) {
        this.event.calendar = this.calendarComputed[0].id;
      }

      if (this.$store.getters["auth/isStudio"]) {
        this.event.location = "";
        if (this.user.studio.address)
          this.event.location += this.user.studio.address;
        if (this.user.studio.number) {
          if (this.event.location !== "") this.event.location += ", ";
          this.event.location += this.user.studio.number;
        }
        if (this.user.studio.piso) {
          if (this.event.location !== "") this.event.location += " ";
          this.event.location += this.user.studio.piso;
        }
        if (this.user.studio.puerta) {
          if (this.event.location !== "") this.event.location += " ";
          this.event.location += this.user.studio.puerta;
        }
        if (this.user.studio.city) {
          if (this.event.location !== "") this.event.location += ", ";
          this.event.location += this.user.studio.city;
        }
        if (this.user.studio.postal_code) {
          if (this.event.location !== "") this.event.location += ", ";
          this.event.location += this.user.studio.postal_code;
        }

        this.fetchStudioTattooers();
      }

      if (this.$store.getters["auth/isTattooer"]) {
        this.fetchTattooerStudios();
        //this.changeTattooer();
      }
      this.action = "save";
      this.dialog = true;
      this.$nextTick(() => {
        if (this.$refs.money) this.$refs.money.change();
      });
      console.log("event", this.event);
      this.fetchBoxes();
    },
    loadSelectors() {
      if (this.$store.getters["auth/isStudio"]) this.fetchStudioTattooers();
      if (this.$store.getters["auth/isTattooer"]) this.fetchTattooerStudios();
      this.fetchBoxes();
    },
    parseToQuarter(time) {
      let ts = time.split(":");
      let t = ts[0] + ":";
      if (+ts[1] < 15) {
        t += "00";
      } else if (+ts[1] < 30) {
        t += "15";
      } else if (+ts[1] < 45) {
        t += "30";
      } else {
        t += "45";
      }
      ////console.log("transformed", ts, t, +ts[1]);
      return t;
    },
    openEditEvent(event) {
      /*if (
        this.hours.length === 0 ||
        this.$store.getters["auth/getLaborExange"] === null
      ) {
        this.$confirm(this.$t("appointments.info_timetable"), "", "").then(
          () => {
            this.$router.push({
              name: "UserSettings",
              params: { type: "alerts", calendar: true },
            });
          }
        );
        return;
      }*/
      this.errors.clear();
      let start;
      let end;

      if (!event.all_day) {
        start = event.start.split(" ");
        end = event.end.split(" ");
        start[1] = this.parseToQuarter(start[1]);
        end[1] = this.parseToQuarter(end[1]);
      }

      this.event = {
        id: event.id,
        title: event.name,
        startDate: event.all_day ? "" : start[0],
        endDate: event.all_day ? "" : end[0],
        startTime: event.all_day ? "" : start[1],
        endTime: event.all_day ? "" : end[1],
        isAllDay: event.all_day ? "" : start.length === 1,
        timeZone: this.timeZone,
        location: event.location,
        guests: event.attendees ? event.attendees.map((x) => x.email) : [],
        clientMail: null,
        calendar: event.calendar_id,
        otherTattooerMail: [],
        description: event.description,
        type: this.eventTypes.EVENT,
        calendar_id: event.calendar_id,
        url: event.url,
      };
      //consele.log(this.event);
      switch (event.mail) {
        case "Google":
          this.event.account = 1;
          break;
        case "Outlook":
          this.event.account = 2;
          break;
        case "Quickink":
          this.event.account = 0;
          break;
        case "Reminder":
          this.event.type = this.eventTypes.REMINDER;
          break;
      }
      this.event.email = this.selectedEmail;
      /*
        guests: [],
        clientMail: null,
        otherTattooerMail: [],
        tokenPayment: {
          price: null,
          size: null,
        },
        type: this.eventTypes.EVENT,
      };
      if (this.accounts.length === 1) {
        this.event.account = this.accounts[0].mail_id;
      }
      if (this.$store.getters["auth/isStudio"]) {
        this.event.location = `${this.user.studio.address}, ${this.user.studio.city}, ${this.user.studio.postal_code}, ${this.user.studio.country}`;
        this.event.tattooers = this.fetchStudioTattooers();
      }

      if (this.$store.getters["auth/isTattooer"]) {
        this.event.studios = this.fetchTattooerStudios();
        this.fetchTattooerPricing(this.user.id);
      }*/
      ////console.log(this.event);
      this.action = "edit";
      this.dialog = true;
    },
    ...mapActions("tattooers", [
      "getTattooerStudiosByDate",
      "getTattooerPricing",
      "getTattooerTattooTypes",
    ]),
    fetchTattooerStudios() {
      this.getTattooerStudiosByDate({
        tattooer_id: this.user.id,
        date: this.event.startDate,
        start_time: this.event.startTime,
        end_time: this.event.endTime,
      }).then((data) => {
        this.tattooerStudios = data.filter((x) => {
          if (x.limitations) {
            return x.limitations.appointment_new;
          }
          return true;
        });

        if (this.event.studio_id) {
          let a = data.find((x) => x.studio_id === this.event.studio_id);
          if (!a) {
            this.$alert(
              "El estudio seleccionado no está activo para esta fecha"
            );
            this.event.studio_id = data[0].studio_id;
            this.dates = data[0].studio.vacations;
            this.fetchAllVacationsTattooer("none", data[0].studio.id);
            this.fetchBoxes();
          }
        } else if (!this.event.studio_id) {
        }
      });
    },
    selectStudio(studio) {
      this.event.studio_id = studio.studio_id;
      this.dates = studio.studio.vacations;
      this.fetchTimeTables();
      this.fetchAllVacationsTattooer("none", studio.studio.id);
      this.fetchBoxes();
      this.fetchServiceTattooer();
    },
    fetchTattooerPricing(tattooer_id) {
      this.getTattooerPricing(tattooer_id).then((data) => {
        this.tattooerPricing = data;
      });
      if (this.$store.getters["auth/isStudio"]) {
        this.styles = this.studioTattooers.find(
          (x) => x.tattooer_id === tattooer_id
        ).tattooer.tattoo_type;
        //consele.log(tattooer_id);
        this.fetchAllVacationsTattooer(tattooer_id, this.user.id);
      } else {
        this.getTattooerTattooTypes(
          this.$store.state.auth.user.tattooer.id
        ).then((data) => {
          this.styles = data;
        });
      }
    },
    ...mapActions("studios", ["getStudioTattooersByDate", "getStudio"]),
    ...mapActions("tattooers", ["getTattooer"]),
    fetchTimeStudio() {
      let studio_id = null;
      if (this.$store.getters["auth/isStudio"])
        this.getStudio(this.$store.state.auth.user.studio.id).then((data) => {
          ////console.log(data);
          this.timeStudio = data.timetable;
          ////console.log(this.timeStudio);
        });
      else if (this.$store.getters["auth/isTattooer"]) {
        ////console.log(this.$store.state.auth.user);
        this.getTattooer(this.$store.state.auth.user.tattooer.id).then(
          (data) => {
            //consele.log(data);
            if (data.tattooer_studios.length) {
              this.timeStudio = data.tattooer_studios[0].studio.timetable;
            }
          }
        );
      }
    },
    fetchStudioTattooers() {
      let date = new Date();
      if (this.event.startDate) {
        date = this.event.startDate;
      }
      this.getStudioTattooersByDate({
        studio_id: this.user.studio.id,
        date: date,
        start_time: this.event.startTime,
        end_time: this.event.endTime,
      }).then((data) => {
        this.studioTattooers = data;
        if (this.event.tattooer_id) {
          let a = data.find((x) => x.tattooer_id === this.event.tattooer_id);
          if (!a) {
            this.$alert(
              "El artista seleccionado no está activo para esta fecha"
            );
            this.event.tattooer_id = null;
            //consele.log(this.event.tattooer_id);
          }
        }
        this.fetchAllVacationsTattooer(this.event.tattooer_id, this.user.id);
        /*this.studioTattooers.push({
          tattooer_id: 0,
          tattooer: {
            user: {
              first_name: this.$t("other"),
              last_name: this.$tc("tattooer", 1),
            },
          },
        });*/
      });
    },
    onBlurSplit() {
      this.guestsString = this.guestsString.replace(/ /g, "");
      this.event.guests.push(...this.guestsString.split(","));
      this.event.guests = this.event.guests.filter(Boolean);
      this.event.guests = [...new Set(this.event.guests)];
      this.guestsString = "";
      this.email_error = false;
    },
    onBlurSet() {
      if (this.otherTattooerMail) {
        this.event.otherTattooerMail = [];
        this.event.otherTattooerMail.push(this.otherTattooerMail);
        this.otherTattooerMail = null;
      }
    },
    deleteGuest(index) {
      this.event.guests.splice(index, 1);
    },
    deleteClient() {
      this.event.clientMail.splice(0, 1);
    },
    deleteOtherTattooer() {
      this.event.otherTattooerMail.splice(0, 1);
    },
    checkMailFormat(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    formatEventDate(startDateTime, endDateTime) {
      if (startDateTime.substring(0, 10) == endDateTime.substring(0, 10)) {
        if (startDateTime.length > 10) {
          return (
            this.$d(new Date(startDateTime.substring(0, 10))) +
            " " +
            startDateTime.substring(11, 16) +
            " - " +
            endDateTime.substring(11, 16)
          );
        } else {
          return this.$d(new Date(startDateTime));
        }
      } else {
        return (
          this.$d(new Date(startDateTime.substring(0, 10))) +
          " " +
          startDateTime.substring(11, 16) +
          " - " +
          this.$d(new Date(endDateTime.substring(0, 10))) +
          " " +
          endDateTime.substring(11, 16)
        );
      }
    },
    formatAttendees(attendees) {
      let attendee = null;
      let result = "";
      for (attendee of attendees) {
        result = result + attendee.email + "<br/>";
      }
      return result;
    },
    validateForm(scope) {
      this.errors.clear;
      this.$validator.validateAll(scope).then((result) => {
        let timeVal = true;
        let startDate = new Date(
          `${this.event.startDate} ${this.event.startTime}`
        );
        let endDate = new Date(`${this.event.endDate} ${this.event.endTime}`);
        if (
          !this.event.isAllDay &&
          this.event.type !== this.eventTypes.REMINDER &&
          (this.event.startTime == null ||
            this.event.endTime == null ||
            this.timeError)
        ) {
          timeVal = false;
          /*this.$store.commit(
            "app/SET_NOTIFICATION",
            {
              message: this.$t("errors.timeRequired"),
              show: true,
              color: "error",
            },
            { root: true }
          );*/
        } else if (startDate.getTime() > endDate.getTime()) {
          timeVal = false;
          /*this.$store.commit(
            "app/SET_NOTIFICATION",
            {
              message: this.$t("errors.wrongTime"),
              show: true,
              color: "error",
            },
            { root: true }
          );*/
        }
        let mailsOk = true;

        if (
          this.event.otherTattooerMail.length != 0 &&
          !this.checkMailFormat(this.event.otherTattooerMail[0])
        ) {
          mailsOk = false;
          this.$store.commit(
            "app/SET_NOTIFICATION",
            {
              message: this.$t("errors.wrongTattooerMail"),
              show: true,
              color: "error",
            },
            { root: true }
          );
        } else if (
          this.event.type == this.eventTypes.APPOINTMENT &&
          this.event.tattooer_id != null &&
          this.event.tattooer_id == 0 &&
          this.event.otherTattooerMail.length == 0
        ) {
          mailsOk = false;
          this.$store.commit(
            "app/SET_NOTIFICATION",
            {
              message: this.$t("errors.noTattooerMail"),
              show: true,
              color: "error",
            },
            { root: true }
          );
        }
        for (let i = 0; i < this.event.guests.length && mailsOk; i++) {
          let mail = this.event.guests[i];
          ////console.log(mail);
          if (!this.checkMailFormat(mail)) {
            mailsOk = false;
            this.$store.commit(
              "app/SET_NOTIFICATION",
              {
                message: this.$t("errors.wrongGuestMail"),
                show: true,
                color: "error",
              },
              { root: true }
            );
          }
        }
        if (this.event.tattooer_id == 0 && this.event.tokenPayment.price) {
          this.event.tokenPayment = {
            price: null,
            size: null,
          };
        }
        let date = true;
        if (this.dateError) {
          date = false;
        }
        this.date = this.date;

        if (result && timeVal && mailsOk && date && !this.horarioError) {
          ////console.log(this.event);
          this.checkEvent({ formData: this.event }).then((response) => {
            if (response.can) {
              this.send();
            } else {
              if (response.box) this.$alert(this.$t("event_save_cant"), "", "");
              else if (response.box_tattooer)
                this.$alert(this.$t("event_save_cant_tattooer"), "", "");
              else if (response.client)
                this.$confirm(this.$t("event_save_client"), "", "").then(() => {
                  this.send();
                });
              else if (response.no_mail)
                this.$confirm(
                  this.$t("event_save_client_no_mail"),
                  "",
                  ""
                ).then(() => {
                  this.send();
                });
              else
                this.$confirm(this.$t("event_save_confirm"), "", "").then(
                  () => {
                    this.send();
                  }
                );
            }
          });
        }
      });
    },
    ...mapActions("appointments", ["deleteOrArchivate"]),
    removeEvent({ id, mail, calendar_id, url }) {
      if (url) {
        console.log(url);
        url = url.split("/");
        let a_id = url[url.length - 1];
        console.log(id);
        this.$confirm(
          this.$t("¿Seguro que quieres eliminar esta sesión?")
        ).then(() => {
          this.deleteOrArchivate({
            id: a_id,
            action: "delete_session",
            event_id: id,
          }).then(() => {
            this.loadEventsAgenda();
            this.loadEvents({ start: this.start, end: this.end });
            this.selectedOpen = false;
          });
        });
      } else
        this.$confirm(this.$t("delete_event_question"), "", "").then(() => {
          this.deleteEvent({
            event_id: id,
            account: mail,
            email: this.selectedEmail,
            calendar_id,
          }).then((response) => {
            this.selectedOpen = false;
            this.loadEventsAgenda();
            this.loadEvents({ start: this.start, end: this.end });
            this.$alert(this.$t("delete_event"), "", "");
          });
        });
    },
    send() {
      if (this.action === "save") {
        //consele.log(this.event);
        this.saveEvent({
          formData: { ...this.event, send: this.email_val },
        }).then((response) => {
          this.dialog = false;
          this.guestsString = "";
          this.fetchCalendars();
          //this.loadEvents({ start: this.start, end: this.end });
          ////console.log(this.$refs.calendar);

          let mail = "";
          for (let i = 0; i < this.event.guests.length; i++) {
            if (mail == "") {
              mail = this.event.guests[i];
            } else {
              mail = mail + ", " + this.event.guests[i];
            }
          }
          this.event.type === "appointment"
            ? this.$alert(this.$t("create_appointment_success"), "", "")
            : this.$alert(this.$t("create_event_success"), "", "");
        });
      } else {
        ////console.log("edit");
        this.editEvent({
          event_id: this.event.id,
          formData: { ...this.event, send: this.email_val },
        }).then((response) => {
          this.dialog = false;
          this.guestsString = "";
          this.loadEventsAgenda();
          this.loadEvents({ start: this.start, end: this.end });
          this.$alert(this.$t("edit_event_success"), "", "");
        });
      }
    },
    fullDayName(d) {
      return this.$t("week_days")[d.weekday];
    },
    del(calendar_id, calendar) {
      this.$confirm(this.$t("delete_calendar"), "", "").then(() =>
        this.deleteCalendar({
          calendar_id,
          calendar,
          email: this.selectedEmail,
        }).then(() => {
          this.$alert(this.$t("confirm_delete_calendar"), "", "");
          this.fetchCalendars();
        })
      );
    },
    saveCalendar(calendar) {
      this.addCalendar({
        formData: this.newCalendar,
        calendar,
        email: this.selectedEmail,
      }).then((data) => {
        this.calendars[calendar].push(data);
        this.selectedCalendars.push(data.id);
        this.newCalendar = {};
        this.dialogCalendar[calendar] = false;
        this.loadSelectors();
      });
    },
    openEditCalendar(editCalendar, calendar) {
      this.newCalendar = {
        ...editCalendar,
        account: calendar,
        email: this.selectedEmail,
      };
      this.dialogCalendar[calendar] = true;
      this.dialogCalendarAction = "edit";
    },
    editCalendar(calendar) {
      this.updateCalendar({
        formData: this.newCalendar,
      }).then((data) => {
        this.newCalendar = {};
        this.dialogCalendar[calendar] = false;
        this.fetchCalendars();
      });
    },
    shareCalendar(shareCalendar, calendar) {
      this.$validator.validateAll("share-form").then((resut) => {
        this.shareCalendars({
          id: shareCalendar.id,
          calendar,
          email: this.selectedEmail,
          share_email: this.shareEmail,
        }).then((data) => {
          this.$alert(this.$t("confirm_share_calendar"), "", "");
          this.shareEmail = null;
        });
      });
    },
  },
  components: {
    timeInput: () => import("@/components/ui/TimeInput"),
    NumberInputWithSeparators: () =>
      import("@/components/ui/NumberInputWithSeparators"),
    AgendaView: () => import("@/components/calendar/AgendaView"),
  },
};
</script>

<style lang="sass">
#customer_select,
#studio_select,
#type_select,
#subtype_select
  .v-card
    border: 1px solid var(--v-primary-base)
    .v-card__title
      padding: 24px
      font-size: 18px !important
      text-decoration: none !important

#calendar
  .v-select .v-select__selections
    text-transform: uppercase !important
  .v-text-field__details
    display: none
  .v-calendar-daily__scroll-area
    background-color: var(--v-gris3-base)
  .v-calendar .v-calendar-daily__day
    background-color: var(--v-gris4-base)
  .day-number
    font-size: 25px
    padding-bottom: 25px
  .v-navigation-drawer .v-input--selection-controls
    margin-top: 0px
  .v-select.v-input--dense .v-select__selection--comma
    margin-top: 1px
    margin-right: 0px !important
  .theme--dark.v-select .v-select__selection--comma
    color: var(--v-primary-base)

  .v-select

    .theme--dark.v-icon
      color: var(--v-primary-base)

  .theme--dark.v-input input, .theme--dark.v-input textarea
    color: var(--v-primary-base)
    text-align: center
    text-transform: uppercase

  .v-calendar-weekly__head .v-calendar-weekly__head-weekday
    margin: 10px
    padding: 6px
    color: var(--v-gris3-base) !important
    text-transform: none
    font-weight: normal

  .theme--dark.v-calendar-weekly
    border: 0px
    background-color: var(--v-gris3-base)
    .v-calendar-weekly__day.v-outside
      background-color: var(--v-gris3-lighten1)
    .v-calendar-weekly__day
      background-color: var(--v-gris3-base)

  .v-calendar-weekly__head
    border-bottom: 1px var(--v-primary-base) solid

  .v-calendar-weekly__week
    border-left: 1px var(--v-primary-base) solid

  .theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside, .theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday
    background-color: var(--v-primary-base)
    border-radius: 50px

  .theme--dark.v-calendar-weekly .v-calendar-weekly__day
    border-right: var(--v-primary-base) 1px solid !important
    border-bottom: var(--v-primary-base) 1px solid
    margin-right: 0px
    color: var(--v-primary-base)
    padding-top: 4px

  .v-event-timed
    border-radius: 10px
    border: 0px

  .v-event-timed-container

    /*margin-top: 10px*/
    margin-left: 10px
    margin-bottom: -20px
    border-radius: 10px

  .v-calendar-weekly__day-label
    position: absolute
    bottom: 5px
    right: 7px

  .v-chip:not(.tattooers) .v-chip__content
    font-size: larger
  .v-chip.tattooers .v-chip__content
    font-size: 14px

  .v-calendar-daily_head-day-label .v-btn
    border: 1px solid var(--v-primary-base) !important
    font-size: 25px
    height: 45px
    width: 45px

  .v-calendar-daily_head-weekday
    background-color: var(--v-primary-base) !important
    width: 90%
    margin: 0 auto
    border-radius: 30px
    padding: 5%
    font-size: 12px
    margin-bottom: 10px
    color: black
    text-transform: capitalize

  .v-calendar
    border: 0px

    .v-calendar-daily__day-interval
      border-color: var(--v-primary-base)

    .v-calendar-daily__intervals-body
      border-right: 1px solid var(--v-primary-base) !important

    .v-calendar-daily__day
      border-color: var(--v-primary-base)

    .v-calendar-daily_head-day
      background-color: var(--v-gris3-base)
      padding-top: 10px
      border: 0px
      border-bottom: 1px solid var(--v-primary-base) !important

    .v-calendar-daily__intervals-head, .v-calendar-daily__intervals-body
      border: 0px
      border-top: 0px !important
      background-color: var(--v-gris3-base)

    .v-calendar-daily__intervals-head::after
      border: 0px
      background-color: var(--v-gris3-base)

  .day .v-calendar-daily__head .v-calendar-daily_head-weekday
    background-color: var(--v-primary-base) !important
    width: 15%
    padding: 5px
    margin-left: 0px
    border-radius: 30px
    margin-bottom: 10px
    color: black

  .day .v-calendar-daily__head .v-calendar-daily_head-day .v-calendar-daily_head-day-label
    width: 15%

.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__prepend-outer
  margin-top: 8px

#createEventDialog
  padding: 35px 35px 0
  .v-input .v-input__prepend-outer .v-icon
    font-size: 20px
    height: 20px

.menu
  display: inline-block
  width: 25%
  height: 30.4px !important
  margin-top: -5.5px !important
.icon_margin
  margin-top: -20px

.v-input .svg-icon
  color: var(--v-primary-base)

.label-color .v-label, .v-select__slot .v-label, .v-input__slot .v-label
  color: white !important

.primary-background
  background-color: var(--v-primary-base) !important

.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday
  border-right: #9e9e9e 0px solid !important
#add_calendar
  .close .v-btn
    top: 0
    right: 0px
    position: absolute
    z-index: 5
    .v-icon
      color: var(--v-primary-base)
      position: absolute

#menu
  min-width: 47px
  top: 315px
  left: 15% !important
  transform-origin: left top
  z-index: 100
#add_calendar,
#shareCalendar
  label
    height: 25px
    display: block
#createEventDialog1 button.btn_toggle
  height: 30px !important
  border-color: var(--v-primary-base) !important
  background-color: transparent
#createEventDialog button.btn_toggle
  height: 30px !important
  border-color: var(--v-primary-base) !important
  background-color: transparent

  &.v-btn--active
    background-color: var(--v-primary-base) !important
    color: var(--v-gris2-base) !important
#createEventDialog
  .close .v-btn
    top: 0
    right: 0px
    position: absolute
    z-index: 5

    .v-icon
      color: var(--v-primary-base)
      position: absolute

.menu

  .v-select.v-text-field--outlined:not(.v-text-field--single-line).v-input--dense .v-select__selections
    //padding-left: 8px
#input-533
  display: none
.v-application--is-ltr .v-text-field .v-input__append-inner

  padding-left: 0px

.lessMargin
  height: 30px
  cursor: pointer
.check
  .actions
    display: none
    float: right
    position: absolute
    z-index: 5
    right: -15px
    float: right
  .label-text
    overflow: hidden
    text-overflow: ellipsis

  .v-input--checkbox
    width: 100%

  .v-input--selection-controls.v-input .v-label
    white-space: nowrap
    min-width: 10px

  &:hover
    .v-input--checkbox

    .actions
      display: inline-block
      background-color: var(--v-gris3-base)
    .label-text
      width: 80px

.v-calendar-daily__intervals-head
  width: 0px !important
  margin-right: 60px

.v-calendar-daily__head
  background-color: var(--v-gris3-base)
@media (max-width: 600px)

  .check
    .actions
      display: inline-block
      z-index: 12
      right: 10px
      background-color: #363636
  #createEventDialog
    >.v-card__actions
      position: fixed
      bottom: 0
      left: 0
      background-color: var(--v-gris3-base)
      right: 0
    >.v-card__text
      position: fixed
      top: 30px
      bottom: 38px
      left: 0
      right: 0
      background-color: var(--v-gris3-base)
      overflow-y: auto
      overflow-x: hidden
    >.v-card__title
      position: fixed
      top: 0
      right: 0
      left: 0
      background-color: var(--v-gris3-base)
      z-index: 1
      height: 55px

  #calendar

    .v-event-timed-container
      margin: 0px
      div
        font-size: 10px

    .v-calendar-daily__intervals-body
      width: 30px !important
    .v-calendar-daily__intervals-head
      margin-right: 30px

    .v-calendar:not(.day)
      .v-calendar-weekly__head-weekday,
      .v-calendar-daily_head-weekday
        white-space: nowrap
        visibility: hidden
        margin-left: 17px !important
        &:first-letter
          visibility: visible
          color: var(--v-primary-base)

      .v-calendar-daily__head .v-calendar-daily_head-day .v-calendar-daily_head-day-label .v-btn
        width: 15px
        height: 15px
        font-size: 15px
    .v-calendar.day
      .v-calendar-daily_head-weekday
        width: 40%

#eventView

  .v-card__text
    font-size: 13px
    color: white !important
  p
    margin-bottom: 5px
.agenda
  border: solid 1px var(--v-primary-base)
  border-radius: 10px
  .event
    &:hover
      cursor: pointer
      background-color: var(--v-gris1-base)
.selected_calendar
  border: 1px var(-v-primary-base) solid
.v-calendar-category .v-calendar-category__column-header
  border-right: 0 !important
.v-calendar-category
  .v-calendar-daily_head-weekday,
  .v-calendar-daily_head-day-label
    display: none
</style>
